import React from "react";

import { ErrorMessage, Field } from "formik";

import {
  getRequiredMark,
  getValidationClass,
  getWrapperClass,
} from "../../../../helpers/form";
// import { useAllUrls } from "../../../../hooks/use-all-urls";
import { CheckboxCustom } from "./CheckboxCustom";

import styles from "../Textfield/Textfield.module.scss";


const Checkbox = ({ field, formik }) => {
  const { errors, touched } = formik;

  const {
    required,
    title,
    webform_key,
    attributes,
    wrapper_attributes: wrapperAttributes,
  } = field;

  /* const abc = getFieldValueText(wrapper_attributes?.class); */

  // const { getCmsNodePrettyUrlByNodeId } = useAllUrls();

  // const getLinks = (text) => {
  //   const pages = {
  //     "#politica_privacidad#": 119,
  //     "#acuerdo_cesion_derechos_imagen#": 2224,
  //   };
  //
  //   const found = text.match(/#(.*)#/gi);
  //
  //   let pattern = null;
  //   let pageNodeId = null;
  //   if (found && found.length > 0) {
  //     pattern = found[0];
  //     pageNodeId = pages[pattern];
  //   }
  //
  //   if (!pageNodeId) {
  //     return text;
  //   }
  //
  //   let pageLink = getCmsNodePrettyUrlByNodeId(pageNodeId);
  //
  //   if (!pageLink) {
  //     return text;
  //   }
  //
  //   return text.replace(pattern, pageLink);
  // };

  // const titleWithLinks = getLinks(title);

  let isRequired = required === "true";
  let requiredMark = getRequiredMark(isRequired);

  let wrapperAttributesClass = "";
  if (wrapperAttributes?.class?.length > 0) {
    wrapperAttributesClass = wrapperAttributes.class.join(" ");
  }

  const attributesClass = attributes?.class ?? "";
  const validationClass = getValidationClass(
    errors[webform_key],
    touched[webform_key]
  );

  const hasErrors = errors[webform_key] && touched[webform_key] ? true : false;

  const fieldClass = `${attributesClass} ${validationClass}`.trim();

  let wrapperClass = getWrapperClass(wrapperAttributesClass);
  const containerClass = `form-group ${webform_key}-container`;

  return (
    <div className={`${containerClass} ${wrapperClass}`}>
      {/* <pre>{JSON.stringify(field, null, 2)}</pre> */}
      <Field name={webform_key}>
        {({ field }) => (
          <div className={fieldClass}>
            <CheckboxCustom
              {...field}
              required={isRequired}
              className={fieldClass}
            >
              {`${title} ${requiredMark}`}
            </CheckboxCustom>
          </div>
        )}
      </Field>
      {hasErrors && (
        <p className={styles.error}>
          <ErrorMessage name={webform_key} />
        </p>
      )}
    </div>
  );
};

export default Checkbox;
