import { Outlet } from "react-router-dom";

import ContextWrapper from "../context/ContextWrapper";
import Layout from "../layout/Layout/Layout";

/**
 * This component renders the layout of the app.
 *
 * Child routes are rendered inside the layout in the `<Outlet />`
 * component provided by react-router.
 */

export default function Root() {
  return (
    <ContextWrapper>
      <Layout>
        <Outlet />
      </Layout>
    </ContextWrapper>
  );
}
