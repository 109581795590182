import { useTranslation } from "react-i18next";

import Content404 from "../components/Content404/Content404";
import Seo from "../components/Seo/Seo";
import { getSiteTitle } from "../helpers/env";

const siteName = getSiteTitle();

export default function NotFound() {
  const { t } = useTranslation();

  const title = t("404 | Página no encontrada");
  const suffix = siteName ? ` | ${siteName}` : "";

  return (
    <>
      <Seo
        metadata={[
          {
            key: "title",
            value: `${title}${suffix}`,
          },
        ]}
      />

      <Content404 title={title}></Content404>
    </>
  );
}
