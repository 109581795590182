import React from "react"

import { Markup } from "interweave"

const FormMarkup = ({ field }) => {
  const { markup } = field

  return (
    <div className="form-group markup-container">
      <Markup noWrap content={markup} />
    </div>
  )
}

export default FormMarkup
