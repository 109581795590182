const IconKutxaLetters = () => {
  return (
    <svg viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_565_1025)">
        <path d="M90 0H0V90H90V0Z" fill="white" />
        <path
          d="M13.1545 19.6028H16.899V26.1845L21.0703 19.6028H24.9838L20.1631 26.8782L25.4818 34.2337H20.928L16.899 27.9099V34.2337H13.1545V19.6028Z"
          fill="#1A1A1A"
        />
        <path
          d="M30.0712 19.6028V28.4436C30.0712 28.9416 30.089 29.6265 30.3292 30.2046C30.5871 30.7827 31.0585 31.2363 31.9746 31.2363C32.8907 31.2363 33.3799 30.8183 33.5933 30.2847C33.8335 29.7332 33.8335 29.0217 33.8335 28.4436V19.6028H37.569V28.4436C37.569 30.8984 37.0354 31.9835 36.1015 32.9351C35.1498 33.9046 33.8246 34.5183 32.0102 34.5183C30.9429 34.5183 29.0039 34.2604 27.7943 32.9529C26.5847 31.6277 26.3357 30.596 26.3357 28.4436V19.6028H30.0712Z"
          fill="#1A1A1A"
        />
        <path
          d="M41.7314 22.8847H38.8408V19.6028H48.3398V22.8847H45.467V34.2337H41.7314V22.8847Z"
          fill="#1A1A1A"
        />
        <path
          d="M53.7652 26.4424L49.2292 19.6028H53.4628L55.9799 23.7741L58.408 19.6028H62.6238L58.15 26.5224L63.2197 34.2337H58.9861L55.8198 29.0573L52.8136 34.2337H48.6333L53.7652 26.4424Z"
          fill="#1A1A1A"
        />
        <path
          d="M70.7709 24.0497L69.1877 29.155H72.4074L70.762 24.0497H70.7709ZM67.5867 34.2335H63.531L69.33 19.5938H72.5497L78.1708 34.2335H74.0795L73.3857 32.1167H68.2627L67.5867 34.2335Z"
          fill="#1A1A1A"
        />
        <path
          d="M13.1545 37.5422H21.1504V40.8242H16.899V43.3145H20.9903V46.5965H16.899V52.1731H13.1545V37.5422Z"
          fill="#1A1A1A"
        />
        <path
          d="M27.0649 37.5422V46.383C27.0649 46.8811 27.0827 47.566 27.3228 48.1441C27.5808 48.7222 28.0521 49.1758 28.9682 49.1758C29.8843 49.1758 30.3735 48.7578 30.587 48.2241C30.8271 47.6727 30.8271 46.9612 30.8271 46.383V37.5422H34.5627V46.383C34.5627 48.8378 34.029 49.9229 33.0951 50.8746C32.1435 51.844 30.8182 52.4577 29.0038 52.4577C27.9365 52.4577 25.9976 52.1998 24.788 50.8924C23.5784 49.5671 23.3293 48.5354 23.3293 46.383V37.5422H27.0649Z"
          fill="#1A1A1A"
        />
        <path
          d="M45.6181 46.4631L45.5381 37.5422H49.1224V52.1731H45.8761L40.2816 43.3501L40.3617 52.1731H36.7773V37.5422H39.997L45.6181 46.4631Z"
          fill="#1A1A1A"
        />
        <path
          d="M55.1528 48.8912H56.7537C59.5465 48.8912 60.3381 46.9522 60.3381 44.8799C60.3381 44.0438 60.1602 43.0032 59.6265 42.1672C59.1907 41.5179 58.4436 40.8241 56.7804 40.8241H55.1617V48.8912H55.1528ZM51.4172 37.5333H56.2646C57.91 37.5333 60.8361 37.5333 62.7306 40.2638C63.7356 41.6513 64.0736 43.2078 64.0736 44.8087C64.0736 48.8022 62.0991 52.1642 56.5403 52.1642H51.4172V37.5244V37.5333Z"
          fill="#1A1A1A"
        />
        <path
          d="M70.8153 41.9894L69.2321 47.0947H72.4518L70.8064 41.9894H70.8153ZM67.6312 52.1732H63.5754L69.3744 37.5334H72.5941L78.2152 52.1732H74.1239L73.4302 50.0564H68.3071L67.6312 52.1732Z"
          fill="#1A1A1A"
        />
        <path
          d="M17.7261 66.8395H22.3956V70.1215H13.1545V67.6667L18.1975 58.7636H13.1545V55.4817H22.3956V58.5057L17.7261 66.8395Z"
          fill="#1A1A1A"
        />
        <path
          d="M27.9721 55.4817H24.2366V70.1215H27.9721V55.4817Z"
          fill="#1A1A1A"
        />
        <path
          d="M33.0329 62.7839C33.0329 65.0163 34.5538 67.1153 37.0709 67.1153C39.7213 67.1153 41.011 64.705 41.011 62.8195C41.011 60.9339 39.7213 58.488 37.0353 58.488C34.3492 58.488 33.0418 60.7026 33.0418 62.7572V62.775L33.0329 62.7839ZM29.2974 62.9618C29.2974 58.1767 32.9795 55.2061 37.0086 55.2061C41.5179 55.2061 44.7465 58.7281 44.7465 62.8017C44.7465 66.8752 41.5446 70.3973 36.9908 70.3973C32.437 70.3973 29.2974 66.7329 29.2974 62.9795V62.9618Z"
          fill="#1A1A1A"
        />
        <path
          d="M51.5951 59.9377L50.0119 65.0429H53.2316L51.5862 59.9377H51.5951ZM48.411 70.1215H44.3552L50.1542 55.4817H53.3739L58.995 70.1215H54.9037L54.21 68.0047H49.0869L48.411 70.1215Z"
          fill="#1A1A1A"
        />
      </g>
      <defs>
        <clipPath id="clip0_565_1025">
          <rect width="90" height="90" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconKutxaLetters;
