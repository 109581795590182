const IconArrowLink = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5343 6.47297L6.10438 0.149414L5.10174 1.13547L9.86655 5.82147H0V7.22008H9.91576L5.19309 11.8646L6.19573 12.8507L12.6065 6.54593L12.5333 6.47393L12.5343 6.47297Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default IconArrowLink;
