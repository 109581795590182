import React, { createContext, useState, useContext, useEffect } from "react";

import getRelativeParentMenu from "../helpers/getRelativeParentMenu";
import getSelectedChild from "../helpers/getSelectedChild";
import useScroll from "../hooks/use-scroll";
import { AppContext } from "./ContextWrapper";

export const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
  const { actualPage } = useContext(AppContext);
  const [dropdown, setDropdown] = useState(null);
  const [mouseenter, setMouseEnter] = useState(null);
  const [headerH, setheaderH] = useState(null);
  const [goClose, setgoClose] = useState(false);

  const handleDropdown = (link) => {
    if (dropdown?.title === link.title) {
      setgoClose(true);
      setTimeout(() => {
        setgoClose(false);
        return setDropdown(null);
      }, 400);
    }

    return setDropdown(link);
  };

  const handleMouseEnter = () => {
    return setMouseEnter(true);
  };
  const handleMouseLeave = () => {
    return setMouseEnter(false);
  };
  const getHeaderHeight = (height) => {
    if (!height) {
      return false;
    }
    return setheaderH(height);
  };
  const handleClick = () => {
    return setDropdown(null);
  };

  const selectedParent = getRelativeParentMenu(actualPage);
  const selectedChild = getSelectedChild(actualPage);

  useEffect(() => {
    setTimeout(() => {
      setDropdown(null);
    }, 300);
  }, [actualPage, selectedChild, selectedParent]);

  const y = useScroll();
  const isScrolled = y > 50 || dropdown !== null;

  return (
    <MenuContext.Provider
      value={{
        dropdown,
        handleDropdown,
        isScrolled,
        mouseenter,
        handleMouseLeave,
        handleMouseEnter,
        getHeaderHeight,
        headerH,
        handleClick,
        selectedParent,
        selectedChild,
        goClose,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
