const getApiUrl = () => {
  return process.env.REACT_APP_API_URL || "";
};

const getCmsUrl = () => {
  return process.env.REACT_APP_CMS_URL || "";
};

const getSiteTitle = () => {
  return process.env.REACT_APP_SITE_TITLE || "";
};

const getCmsBasicAuth = () => {
  return {
    username: process.env.REACT_APP_CMS_BASIC_AUTH_USER || "",
    password: process.env.REACT_APP_CMS_BASIC_AUTH_PASS || "",
  };
};

const getReCaptchaKey = () => {
  return process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || "";
};

const getGtmId = () => {
  return process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || "";
};

export {
  getApiUrl,
  getCmsUrl,
  getSiteTitle,
  getCmsBasicAuth,
  getReCaptchaKey,
  getGtmId,
};
