import { useTranslation } from "react-i18next";

import styles from "./Loader.module.scss";

const Loader = () => {
  const { t } = useTranslation();

  return (
    <span className={styles.loader}>
      <span className={styles["loader__content"]}>
        {t("El contenido se está cargando")}
      </span>
    </span>
  );
};

export default Loader;
