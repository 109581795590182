import PropTypes from "prop-types";

const VideoLoop = ({ sources }) => {
  return (
    <video muted playsInline autoPlay loop>
      {sources.map((source) => (
        <source key={source.src} src={source.src} type={source.type} />
      ))}
    </video>
  );
};

export default VideoLoop;

VideoLoop.propTypes = {
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      type: PropTypes.string,
    }).isRequired
  ),
};

VideoLoop.defaultProps = {
  sources: [{ src: "", type: "video/mp4" }],
};
