import { useContext } from "react";
import { useTranslation } from "react-i18next";

import classnames from "classnames";

import { AppContext } from "../../context/ContextWrapper";
import Link from "../../elements/Link/Link";
import { sendEvent } from "../../helpers/gtm";
import { getEnabledLanguages } from "../../helpers/locales";
import {
  getLocalizedHomeUrl,
  getLocalizedUrl,
  getUnlocalizedUrl,
} from "../../helpers/localized-url";
import locales from "../../locales";

import styles from "./LanguageSwitcher.module.scss";


const LanguageSwitcher = ({
  localizedSlugs = [],
  scrolled = false,
  mouseenter = false,
}) => {
  const enabledLanguages = getEnabledLanguages(locales);
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const { isHome } = useContext(AppContext);
  const languageSwitcherClass = classnames(
    styles.languageSwitcher,
    scrolled && styles.scrolled,
    mouseenter && styles.scrolled,
    !isHome && styles["is-sticky"]
  );

  return (
    Object.keys(enabledLanguages).length > 0 && (
      <div className={languageSwitcherClass}>
        {Object.keys(enabledLanguages)
          .reverse()
          .map((localeCode) => {
            const targetUrl = formatTargetUrl(localeCode, localizedSlugs);
            const linkClass = classnames(
              styles.link,
              locale === localeCode && styles.linkActive,
              scrolled && styles.scrolled,
              mouseenter && styles.scrolled,
              !isHome && styles["is-sticky"]
            );

            return (
              <Link
                className={linkClass}
                to={targetUrl}
                key={localeCode}
                reloadDocument={true}
                onClick={() => {
                  // Send event to GTM
                  sendEvent("click_language", {
                    click_detail: localeCode,
                  });
                }}
              >
                {localeCode}
              </Link>
            );
          })}
      </div>
    )
  );
};

export default LanguageSwitcher;

const formatTargetUrl = (locale, localizedSlugs) => {
  let slug = localizedSlugs?.[locale] || "";

  if (slug !== "") {
    slug = getUnlocalizedUrl(slug);
  }

  if (!slug || slug === "/home") {
    return getLocalizedHomeUrl(locale);
  }

  return getLocalizedUrl(locale, slug);
};
