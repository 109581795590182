import useNodeData from "./use-node-data";

const useBackUrlData = () => {
  const { getNodeData } = useNodeData();
  const nodeData = getNodeData();

  const getBlockData = () => {
    let dataUrl = [];

    if (!nodeData || !nodeData?.field_pagina_sidebar) {
      return null;
    }

    if (nodeData?.breadcrumbs) {
      const entries = Object.entries(nodeData.breadcrumbs);
      if (entries.length > 2) {
        const penultimateEntry = entries[entries.length - 2];
        dataUrl["text"] = penultimateEntry[0];
        dataUrl["url"] = penultimateEntry[1];
        return dataUrl;
      }
      return null;
    }
  };

  return getBlockData();
};

export default useBackUrlData;
