import React, { useRef, useEffect, useState } from "react";
import { useContext } from "react";

import { Markup } from "interweave";

import Share from "../../components/Share/Share";
import SocialNetworks from "../../components/SocialNetworks/SocialNetworks";
import { AppContext } from "../../context/ContextWrapper";
import useBackUrlData from "../../hooks/use-back-url-data";
import useContactAddressData from "../../hooks/use-contact-address-data";
import useCopyrightData from "../../hooks/use-copyright-data";
import useFooterLogoData from "../../hooks/use-footer-logo-data";
import useFooterMenuData from "../../hooks/use-footer-menu-data";
import useSocialNetworksData from "../../hooks/use-social-networks-data";
import { transformFooterLogo } from "../../transforms/transform-footer-logo";
import Copyright from "./components/Copyright/Copyright";
import LegalMenu from "./components/LegalMenu/LegalMenu";

import styles from "./Footer.module.scss";

const Footer = () => {
  const [top, setTop] = useState();
  const contactAddressData = useContactAddressData();
  const socialNetworksData = useSocialNetworksData();
  const footerLogoData = useFooterLogoData();
  const footerMenuData = useFooterMenuData();
  const copyrightData = useCopyrightData();

  const backUrl = useBackUrlData();
  const footerRef = useRef(null);
  const { hideShare } = useContext(AppContext);
  useEffect(() => {
    const handleScroll = () => {
      if (footerRef.current) {
        const { top } = footerRef.current.getBoundingClientRect();
        setTop(top);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {!hideShare && <Share backUrl={backUrl} top={top} />}
      <footer ref={footerRef}>
        <div className={`${styles.footerCol} ${styles.addressandsocial} `}>
          {contactAddressData && (
            <Markup
              tagName="div"
              attributes={{ className: styles.address }}
              content={contactAddressData}
            />
          )}
          <div className={styles.social}>
            {socialNetworksData && (
              <SocialNetworks links={socialNetworksData} />
            )}
            {footerLogoData && (
              <Markup
                content={footerLogoData}
                transform={transformFooterLogo}
                className={styles["bai-euskarari"]}
              />
            )}
          </div>
        </div>
        <div className={styles.footerCol}>
          {footerMenuData && <LegalMenu links={footerMenuData} />}
          {copyrightData && <Copyright data={copyrightData} />}
        </div>
      </footer>
    </>
  );
};

export default Footer;
