import { useContext, useEffect, useRef, useState } from "react";

import { useHover } from "@react-aria/interactions";
import classNames from "classnames";

import { AppContext } from "../../../context/ContextWrapper";
import { MenuContext, MenuProvider } from "../../../context/MenuContext";
import Dropdown from "./components/Dropdown/Dropdown";
import MainMenu from "./components/MainMenu/MainMenu";
import SecondaryMenu from "./components/SecondaryMenu/SecondaryMenu";

import styles from "./HeaderDesktop.module.scss";

const HeaderDesktop = ({ openWebformModal }) => {
  return (
    <MenuProvider>
      <Header>
        <MainMenu />
        <SecondaryMenu openWebformModal={openWebformModal} />
      </Header>
      <Dropdown />
    </MenuProvider>
  );
};

const Header = ({ children }) => {
  const headRef = useRef(null);
  const {
    dropdown,
    isScrolled,
    mouseenter,
    handleMouseEnter,
    handleMouseLeave,
    getHeaderHeight,
  } = useContext(MenuContext);

  const { isHome } = useContext(AppContext);

  const [events, setEvents] = useState([]);
  let { hoverProps, isHovered } = useHover({
    onHoverStart: (e) => {
      setEvents((events) => [
        ...events /* , `hover start with ${e.pointerType}` */,
      ]);
    },
    onHoverEnd: (e) => {
      setEvents((events) => [
        ...events /* , `hover end with ${e.pointerType}` */,
      ]);
    },
  });
  useEffect(() => {
    let headerHeight = headRef.current.offsetHeight;

    if (headerHeight && getHeaderHeight) {
      getHeaderHeight(headerHeight);
    }
  });

  useEffect(() => {
    // Update the document title using the browser API

    if (isHovered) {
      handleMouseEnter(isHovered);
    } else {
      handleMouseLeave();
    }
  }, [isHovered, handleMouseEnter, handleMouseLeave]);

  const headerClass = classNames(
    styles.header,
    dropdown && styles.isDropdown,
    isScrolled && styles.scrolled,
    mouseenter && styles.scrolled,
    !isHome && styles["is-sticky"]
  );

  return (
    <header
      ref={headRef}
      {...hoverProps}
      className={headerClass}
      {...events}
      style={{ "--headerH": headRef?.current?.offsetHeight }}
    >
      {children}
    </header>
  );
};

export default HeaderDesktop;
