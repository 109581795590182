import { useContext, useState, useEffect } from "react";

import InnerDropdown from "../../../../../components/InnerDropdown/InnerDropdown";
import { MenuContext } from "../../../../../context/MenuContext";

import styles from "./Dropdown.module.scss";

const Dropdown = () => {
  const { dropdown, goClose, headerH } = useContext(MenuContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (dropdown) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }, 100);
  }, [dropdown, goClose]);

  if (!dropdown) {
    return "";
  }
  const { title, children } = dropdown;
  return (
    <div
      className={`${styles.dropdown}  ${open && !goClose ? styles.open : ""} `}
      style={{ "--headerH": headerH + "px" }}
    >
      <p className={styles.dropdownTitle}>{title}</p>
      {children && <InnerDropdown data={children} parent={title} />}
    </div>
  );
};

export default Dropdown;
