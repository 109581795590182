import Obfuscate from "react-obfuscate";

import ButtonLink from "../elements/ButtonLink/ButtonLink";
import Link from "../elements/Link/Link";
import { emailExtractor } from "../helpers/emailExtractor";
import { mailToValidator } from "../helpers/mailToValidator";

import styles from "./transform-bloque-texto-destacado.module.scss";

const calculateAspectRatio = (width, height) => {
  if (!width || !height) {
    return;
  }

  const getGreatestCommonDivisor = (a, b) => {
    return b === 0 ? a : getGreatestCommonDivisor(b, a % b);
  };

  const gcd = getGreatestCommonDivisor(width, height);
  const aspectWidth = width / gcd;
  const aspectHeight = height / gcd;

  return `${aspectWidth} / ${aspectHeight}`;
};

const transformBloqueTextoDestacado = (node, children) => {
  if (node.tagName === "A") {
    const href = node.getAttribute("href");
    const target = node.getAttribute("target");

    if (mailToValidator(href)) {
      return (
        <Obfuscate className="obfuscate" email={emailExtractor(href)}>
          {children}
        </Obfuscate>
      );
    } else if (node.className === "button-black") {
      return <ButtonLink to={href}>{children}</ButtonLink>;
    } else if (node.className === "button-transparent") {
      return (
        <ButtonLink to={href} styleVariant="transparent--dark">
          {children}
        </ButtonLink>
      );
    } else {
      return (
        <Link to={href} className={node.className} target={target}>
          {children}
        </Link>
      );
    }
  }

  if (node.tagName === "IFRAME") {
    const src = node?.getAttribute("src");
    const width = node?.getAttribute("width");
    const height = node?.getAttribute("height");

    if (src) {
      const iframe = document.createElement("iframe");

      Array.from(node.attributes).forEach(({ nodeName, nodeValue }) => {
        iframe.setAttribute(nodeName, nodeValue);
        const aspectRatio = calculateAspectRatio(width, height);
        if (aspectRatio) {
          iframe.setAttribute(
            "style",
            `aspect-ratio: ${aspectRatio}; height: auto`
          );
        }
      });

      return (
        <div
          className={styles.iframe}
          dangerouslySetInnerHTML={{
            __html: iframe.outerHTML,
          }}
        />
      );
    }

    return null;
  }

  if (node.tagName === "TABLE") {
    const table = document.createElement("table");

    Array.from(node.attributes).forEach(({ nodeName, nodeValue }) => {
      if (nodeName === "style") {
        return;
      }

      table.setAttribute(nodeName, nodeValue);
    });

    return (
      <div style={{ overflowX: "auto" }}>
        <table>{children}</table>
      </div>
    );
  }

  node.removeAttribute("style");
};

export { transformBloqueTextoDestacado };
