import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { getLocalizedHomeUrl } from "../../helpers/localized-url";
import IconKutxa from "../../icons/IconKutxa";
import IconKutxaLetters from "../../icons/IconKutxaLetters";
import Link from "../Link/Link";

import styles from "./KutxaHomeLink.module.scss";

const KutxaHomeLink = ({ className }) => {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const isMobile = useMediaQuery({
    query: "(max-width: 1023px) ",
  });
  return (
    <Link
      aria-label="Home page"
      className={className}
      to={getLocalizedHomeUrl(locale)}
    >
      {isMobile ? (
        <IconKutxaLetters />
      ) : (
        <div className={styles.iconpc}>
          <IconKutxa />
        </div>
      )}
    </Link>
  );
};

export default KutxaHomeLink;
