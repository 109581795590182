const sprintf = (string, ...values) => {
  let i = 0;
  return string.replace(/%([sd])/g, (_, type) => {
    const value = values[i++];
    if (type === "d") {
      return Number(value);
    } else {
      return String(value);
    }
  });
};
export default sprintf;
