const getSelectedChild = (datasMenu) => {
  if (!datasMenu?.menu) {
    return false;
  }
  const actualRelative = datasMenu.actual;
  const selected = Object.values(datasMenu.menu).find(
    (e) => e.selected === true
  );

  if (selected?.below) {
    for (const item of selected.below) {
      if (item?.below) {
        for (const section of item.below) {
          if (section.relative === actualRelative) {
            return section.relative;
          }
        }
      }
    }
  }
  return null;
};
export default getSelectedChild;
