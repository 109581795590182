import { Image } from "../elements/Image/Image";
import Link from "../elements/Link/Link";
import { getCmsUrl } from "../helpers/env";

const transformFooterLogo = (node, children) => {
  if (node.tagName === "A") {
    return <Link to={node.getAttribute("href")}>{children}</Link>;
  }

  if (node.tagName === "IMG" && node.getAttribute("src")) {
    const cmsUrl = getCmsUrl();

    return (
      <Image
        images={[`${cmsUrl}${node.getAttribute("src")}`]}
        alt={
          node.getAttribute("alt") ??
          "bai euskarari ziurtagiria - zerbitzua euskaraz"
        }
      />
    );
  }
};

export { transformFooterLogo };
