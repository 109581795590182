const getLocalizedHomeUrl = (locale = "") => {
  if (!locale) {
    return "";
  }

  return `/${locale}`;
};

const getLocalizedUrl = (locale = "", slug = "") => {
  if (!locale || !slug) {
    return getLocalizedHomeUrl();
  }

  return `/${locale}${slug || ""}`;
};

const getUnlocalizedUrl = (slug) => {
  let url = slug.split("/").filter(Boolean);
  url.shift();
  url = `/${url.join("/")}`;

  return url;
};

export { getLocalizedHomeUrl, getLocalizedUrl, getUnlocalizedUrl };
