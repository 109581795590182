import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Image } from "../../../../../elements/Image/Image";
import { getRequiredMark } from "../../../../../helpers/form";
import icon from "./upload.svg";

export const UploadFile = ({
  webform_id,
  webform_key,
  title,
  help_title,
  description,
  fieldClass,
  isRequired,
  setFieldValue,
  setFieldError,
  error,
  ...props
}) => {
  const [fieldName, setFieldName] = useState(null);
  // useEffect(() => {
  //   const name = props.value.split("\\")
  //   setFieldName(name[2])
  // }, [props.value])

  const { t } = useTranslation();

  let requiredMark = getRequiredMark(isRequired);

  const isValidFilename = (filename) => {
    const filenameStr = filename.split(".").slice(0, -1).join(".");

    const isValid = /^[a-zA-Z0-9-_\s.]+$/.test(filenameStr);

    return isValid;
  };

  return (
    <div className={`uploadFile ${fieldClass}`}>
      <input
        name={props.name}
        label={title}
        id={webform_id}
        key={webform_key}
        type="file"
        hidden
        required={isRequired}
        accept="application/pdf"
        onBlur={props.onBlur}
        onChange={(event) => {
          const file = event.currentTarget.files[0];

          if (!file) {
            return null;
          }

          if (!isValidFilename(file.name)) {
            const message = `${t(
              "El nombre del fichero solo puede contener letras, números y guiones."
            )}`;

            setFieldError(webform_key, message);

            return;
          }

          setFieldName(file.name ?? "");

          setFieldValue(webform_key, file);
        }}
      />
      <label className="uploadFile-wrapper" htmlFor={webform_id}>
        <div className="uploadFile-input ">
          {fieldName ?? title} {requiredMark}
        </div>
        <div className="uploadFile-button">
          <Image images={[icon]} alt="" />
        </div>
      </label>
      <p className="mandatory-fields-disclaimer">
        {help_title}{" "}
        {error && <span className="validation-error">{error}</span>}
      </p>
    </div>
  );
};
