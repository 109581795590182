import React from "react"

import { ErrorMessage, Field } from "formik"

import {
  getRequiredMark,
  getValidationClass,
  getWrapperClass,
} from "../../../../helpers/form"

const Tel = ({ field, formik }) => {
  const { errors, touched } = formik

  const {
    required,
    title,
    webform_key,
    webform_id,
    attributes,
    wrapperAttributes,
  } = field

  let isRequired = required === "true"
  let requiredMark = getRequiredMark(isRequired)

  let wrapperAttributesClass = ""
  if (wrapperAttributes?.class?.length > 0) {
    wrapperAttributesClass = wrapperAttributes.class.join(" ")
  }

  const attributesClass = attributes?.class ?? ""
  const validationClass = getValidationClass(
    errors[webform_key],
    touched[webform_key]
  )

  const hasErrors = errors[webform_key] && touched[webform_key] ? true : false

  const fieldClass = `${attributesClass} ${validationClass}`.trim()

  let wrapperClass = getWrapperClass(wrapperAttributesClass)
  const containerClass = `form-group ${webform_key}-container`

  return (
    <div className={`${containerClass} ${wrapperClass}`}>
      <label htmlFor={webform_id}>
        {title}
        {requiredMark}
      </label>
      <div className="field-error-group">
        <Field
          id={webform_id}
          name={webform_key}
          placeholder={title}
          required={isRequired}
          className={fieldClass}
          type="tel"
        />
        {hasErrors && (
          <p>
            <ErrorMessage name={webform_key} />
          </p>
        )}
      </div>
    </div>
  )
}

export default Tel
