import useRegionData from "./use-region-data";

const useFooterMenuData = () => {
  const regionData = useRegionData("footer");

  const getBlockData = () => {
    if (!regionData) {
      return null;
    }

    const block = regionData.find(
      (item) => item.type === "menu" && item.id === "system_menu_block_footer"
    );

    if (!block || !block.data) {
      return null;
    }

    return block.data;
  };

  return getBlockData();
};

export default useFooterMenuData;
