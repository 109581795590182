const getRelativeParentMenu = (datasMenu) => {
  if (!datasMenu?.menu) {
    return false;
  }
  const selected = Object.values(datasMenu.menu).find(
    (e) => e.selected === true
  );

  if (selected) {
    return selected.title;
  }
  return null;
};
export default getRelativeParentMenu;
