import { initReactI18next } from "react-i18next";

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

import { getAllLiteralsApiUrl } from "./helpers/api";
import { getCmsBasicAuth } from "./helpers/env";
import { getDefaultLanguage, getEnabledLanguages } from "./helpers/locales";
import locales from "./locales";

const isProduction = process.env.NODE_ENV === "production";

const defaultLanguage = getDefaultLanguage(locales);
const enabledLanguages = getEnabledLanguages(locales);

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: !isProduction,
    fallbackLng: defaultLanguage,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: [
        "path",
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
        "htmlTag",
        "subdomain",
      ],
    },
    supportedLngs: enabledLanguages ? Object.keys(enabledLanguages) : null,
    saveMissing: !isProduction,
    backend: {
      loadPath: (lng) => getAllLiteralsApiUrl(lng),
      addPath: (lng) => `${getAllLiteralsApiUrl(lng)}/add`,
      //parse: (data) => data,
      customHeaders: () => {
        const { username, password } = getCmsBasicAuth();

        if (username && password) {
          return {
            authorization: "Basic " + btoa(username + ":" + password),
          };
        }

        return null;
      },
    },
    returnEmptyString: false,
  });

// TODO: AV: We may need to refactor this to use the backend above, without this extra call, but right now is not working
// and sadly I don't have enough time to keep researching and fix that.
const { username, password } = getCmsBasicAuth();
const base64Credentials = btoa(`${username}:${password}`);

// Load translations with authentication
fetch(getAllLiteralsApiUrl("eu"), {
  headers: {
    Authorization: `Basic ${base64Credentials}`,
  },
})
  .then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error("Failed to fetch translations: Unauthorized");
    }
  })
  .then((translations) => {
    i18n.addResourceBundle("eu", "translation", translations);
  })
  .catch((error) => {
    console.error("Failed to load translations:", error);
  });

export default i18n;
