import { Image } from "../elements/Image/Image";
import VideoLoop from "../elements/Video/VideoLoop";
import { getCmsUrl } from "../helpers/env";

const cmsUrl = getCmsUrl();

const transformMediaMarkup = (node) => {
  if (!["IMG", "VIDEO"].includes(node.tagName)) {
    return <></>;
  }

  if (node.tagName === "IMG" && node.getAttribute("src")) {
    return (
      <Image
        images={[`${cmsUrl}${node.getAttribute("src")}`]}
        alt={node.getAttribute("alt") ?? ""}
      />
    );
  }

  if (node.tagName === "VIDEO") {
    const sourceElements = Array.from(node.querySelectorAll("source"));

    const sources = sourceElements.map((source) => {
      return {
        src: `${cmsUrl}${source.getAttribute("src")}`,
        type: source.getAttribute("type"),
      };
    });

    return <VideoLoop sources={sources} />;
  }
};

export { transformMediaMarkup };
