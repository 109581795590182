const getRequiredMark = (isRequired) => {
  return isRequired ? "*" : "";
};

const getValidationClass = (errors, touched) => {
  let className = "";

  if (touched && errors) {
    className = "validation-error";
  } else if (touched && !errors) {
    className = "validation-success";
  }

  return className;
};

const getWrapperClass = (wrapperAttributesClass, fallbackClass = "") => {
  return wrapperAttributesClass !== "" ? wrapperAttributesClass : fallbackClass;
};

export { getRequiredMark, getValidationClass, getWrapperClass };
