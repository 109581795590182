const colorsApi = (storeDatas) => {
  let colors = [];
  if (!storeDatas) {
    return null;
  }
  colors = getNodeColors(storeDatas?.nodeData);

  getRegionsColors(storeDatas?.regionsData?.header, colors);
  return colors;
};
const getNodeColors = (dataNode) => {
  let colors = [];

  if (dataNode?.["field_color_zona_i"]) {
    colors["field_color_zona_i"] =
      dataNode["field_color_zona_i"]?.[0]?.["field_color"]?.[0]?.["color"] ??
      "";
  }
  if (dataNode?.["field_color_zona_ii"]) {
    colors["field_color_zona_ii"] =
      dataNode["field_color_zona_ii"]?.[0]?.["field_color"]?.[0]?.["color"] ??
      "";
  }
  return colors;
};
const getRegionsColors = (dataRegion, colors) => {
  if (!dataRegion) {
    return colors;
  }
  const dataHeader = dataRegion.find(
    (e) => e.id === "views_block_colores_kutxa-block_1"
  );

  if (dataHeader && dataHeader?.data?.rows) {
    const shuffleColors = shuffle(dataHeader?.data?.rows);
    if (!colors["field_color_zona_i"]) {
      colors["field_color_zona_i"] = shuffleColors?.[0].field_color || "";
    }
    if (!colors["field_color_zona_ii"]) {
      colors["field_color_zona_ii"] = shuffleColors?.[1].field_color || "";
    }
  }
  return colors;
};
const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};
export default colorsApi;
