import useRegionData from "./use-region-data";

const useSocialNetworksData = () => {
  const regionData = useRegionData("footer");

  const getBlockData = () => {
    if (!regionData) {
      return null;
    }

    const block = regionData.find(
      (item) => item.type === "view" && item.id === "views_block_rrss-block_1"
    );

    if (!block || !block.data || !block.data.rows) {
      return null;
    }

    return block.data.rows;
  };

  return getBlockData();
};

export default useSocialNetworksData;
