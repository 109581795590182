import useRegionData from "./use-region-data";

const useSideBannerData = () => {
  const regionData = useRegionData("header");

  const getBlockData = () => {
    if (!regionData) {
      return null;
    }

    const block = regionData.find(
      (item) =>
        item.type === "html" &&
        item.id === "block_content_870acd9d-ff0c-4554-9d9f-88f882475b44"
    );

    if (!block || !block.data) {
      return null;
    }

    return { text: block.data ?? "", link: block.link ?? "" };
  };

  return getBlockData();
};

export default useSideBannerData;
