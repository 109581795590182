import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

import CmsForm from "../../components/Form/CmsForm";
import Modal from "../../components/Modal/Modal";
import Seo from "../../components/Seo/Seo";
import { AppContext } from "../../context/ContextWrapper";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import SideBanner from "../Header/SideBanner/SideBanner";

import modalStyles from "../../components/Modal/Modal.module.scss";
import styles from "./Layout.module.scss";


const Layout = ({ children }) => {
  const urlParams = new URLSearchParams(useLocation().search);

  const { store } = useContext(AppContext);

  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const [webformModalIsOpen, setWebformModalIsOpen] = useState(false);

  const openWebformModal = () => {
    setWebformModalIsOpen(true);
  };
  const closeWebformModal = () => {
    setWebformModalIsOpen(false);
  };

  const webform =
    store?.regionsData?.header?.find((obj) => obj.id === "webform_block")
      .data || null;

  useEffect(() => {
    if (urlParams.has("subscribe")) {
      openWebformModal();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Seo />
      <Header openWebformModal={openWebformModal} />

      <main className={styles.layout}>
        {isDesktop && <SideBanner />}
        {children}
      </main>
      <Footer />

      {webform && (
        <Modal
          isOpen={webformModalIsOpen}
          onRequestClose={closeWebformModal}
          className={modalStyles["content--newsletter"]}
        >
          {webform?.elements?.length > 0 && <CmsForm formData={webform} />}
        </Modal>
      )}
    </>
  );
};

export default Layout;
