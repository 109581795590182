import React, { createContext, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { getRegionsApiUrl, submitRequest } from "../helpers/api";
import colorsApi from "../helpers/colorsApi";

export const AppContext = createContext(null);

const ContextWrapper = (props) => {
  const [store, setStore] = useState({
    nodeData: null,
    regionsData: null,
  });

  // Check if we are in home page
  const location = useLocation();

  const [isHome, setIsHome] = useState(null);
  const [hideShare, setHideShare] = useState(null);
  const [template, setTemplate] = useState(null);
  const [viewColors, setViewColors] = useState([]);
  const [actualPage, setActualPage] = useState([]);
  const [expandido, setExpandido] = useState([]);
  const [meInteresa, setMeInteresa] = useState(null);
  const [meInteresaLink, setMeInteresaLink] = useState(null);
  const [meInteresaName, setMeInteresaName] = useState(null);
  const [mobileVideo, setMobileVideo] = useState(null);
  const [desktopVideo, setDesktopVideo] = useState(null);

  useEffect(() => {
    const regex = /^\/(eu|es)\/?$/;
    setIsHome(regex.test(location.pathname));
  }, [location.pathname]);

  const { i18n } = useTranslation();
  const locale = i18n.language;

  const fetchRegions = useCallback(async () => {
    const regionIds = ["header", "footer"];

    const allRegions = await Promise.all(
      regionIds.map(async (regionId) => {
        const regionUrl = getRegionsApiUrl(locale, regionId);

        const response = await submitRequest(regionUrl);
        const regionData = await response.json();

        return { [regionId]: regionData };
      })
    );

    return allRegions.reduce((accumulator, currentValue) => {
      return { ...accumulator, ...currentValue };
    });
  }, [locale]);

  useEffect(() => {
    fetchRegions()
      .then((regionsData) => {
        setStore((prevStore) => {
          return { ...prevStore, regionsData };
        });
      })
      .catch(console.error);
  }, [fetchRegions]);

  useEffect(() => {
    const getColors = () => {
      if (!store) {
        return false;
      }
      let storeDatas = { ...store };
      let colors = [];
      if (!storeDatas) {
        return "";
      }

      colors = colorsApi(storeDatas);
      setViewColors(colors);
    };

    if (store && (store.nodeData || store.regionsData)) {
      getColors();

      if (store.nodeData && store.nodeData["main-menu"]) {
        setActualPage({
          actual: store.nodeData.urls[locale],
          menu: store.nodeData["main-menu"],
          breadcrumbs: store.nodeData.breadcrumbs,
        });
      }
    }
  }, [store, locale]);

  useEffect(() => {
    const templateWithoutShare = ["Lineas"];
    const typeWithoutShare = ["lineas_actuacion"];
    const typeExpandido = ["noticia", "ofertas_de_trabajo"];
    const typeMeInteresa = ["proyectos", "agrupacion"];

    setHideShare(
      store?.nodeData?.nid === 2 ||
        store?.nodeData?.nid === 8 ||
        store?.nodeData?.nid === 32 ||
        store?.nodeData?.nid === 34 ||
        store?.nodeData?.nid === 40 ||
        store?.nodeData?.nid === 42 ||
        store?.nodeData?.nid === 60 ||
        store?.nodeData?.nid === 111 ||
        store?.nodeData?.error ||
        templateWithoutShare.includes(store?.nodeData?.field_plantilla_front) ||
        typeWithoutShare.includes(store?.nodeData?.type)
    );
    setExpandido(typeExpandido.includes(store?.nodeData?.type));
    setTemplate(store?.nodeData?.field_plantilla_front);

    setMeInteresa(typeMeInteresa.includes(store?.nodeData?.type));
    setMeInteresaName(store?.nodeData?.title ?? "No hay título");
  }, [store, location.pathname]);

  useEffect(() => {
    setMeInteresaLink(store?.nodeData?.field_me_interesa?.[0]?.uri);
  }, [store]);

  return (
    <AppContext.Provider
      value={{
        store,
        setStore,
        isHome,
        viewColors,
        hideShare,
        actualPage,
        template,
        expandido,
        meInteresa,
        meInteresaLink,
        meInteresaName,
        mobileVideo,
        setMobileVideo,
        desktopVideo,
        setDesktopVideo,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default ContextWrapper;
