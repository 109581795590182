import { useTranslation } from "react-i18next";
import * as ReactModal from "react-modal";

import PropTypes from "prop-types";

import classNames from "classnames";

import { ReactComponent as IconModalClose } from "../../icons/IconModalClose.svg";

import "../../styles/vendors/react-modal/_react-modal.scss";
import styles from "./Modal.module.scss";

const Modal = ({
  children,
  isOpen,
  onAfterOpen,
  onRequestClose,
  contentLabel,
  transparentBg,
  contentType,
  style,
  className,
}) => {
  const { t } = useTranslation();

  const customStyles = {
    content: { ...style },
  };

  const handleAfterOpen = () => {
    document.body.style.overflow = "hidden";
  };

  const handleRequestClose = () => {
    document.body.style.overflow = "unset";
  };

  const wrapperClassName = classNames(styles.wrapper, {
    [styles.transparentBg]: transparentBg,
  });

  const fullClassName = classNames(styles.modal, className);

  return (
    <ReactModal
      isOpen={isOpen}
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleRequestClose}
      onRequestClose={handleRequestClose}
      className={fullClassName}
      overlayClassName={styles.overlay}
      contentLabel={contentLabel}
      parentSelector={() => document.querySelector("#root")}
      appElement={document.querySelector("#root")}
      style={customStyles}
      closeTimeoutMS={300}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
    >
      <div className={styles.cover}>
        {onRequestClose && (
          <button
            className={styles.close}
            onClick={onRequestClose}
            aria-label={t("Close")}
          >
            <IconModalClose />
          </button>
        )}
        <div className={wrapperClassName}>{children}</div>
      </div>
    </ReactModal>
  );
};

export default Modal;

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  onAfterOpen: PropTypes.func,
  onRequestClose: PropTypes.func,
  onAfterClose: PropTypes.func,
  contentLabel: PropTypes.string,
  transparentBg: PropTypes.bool,
  styles: PropTypes.object,
};

Modal.defaultProps = {
  children: null,
  isOpen: false,
  onAfterOpen: () => {},
  onRequestClose: () => {},
  onAfterClose: () => {},
  contentLabel: "",
  transparentBg: false,
  styles: null,
};
