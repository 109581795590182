import { Markup } from "interweave";

import { transformMediaMarkup } from "../../transforms/transform-media-markup";

const MediaMarkup = ({ data }) => {
  if (!data) {
    return "";
  }

  return <Markup content={data} transform={transformMediaMarkup} noWrap />;
};

export default MediaMarkup;
