import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import useKeypress from "react-use-keypress";

import classNames from "classnames";

import { AppContext } from "../../context/ContextWrapper";
import Link from "../../elements/Link/Link";
import { sendEvent } from "../../helpers/gtm";
import { ReactComponent as IconArrowUp } from "../../icons/IconArrowUp.svg";
import { ReactComponent as IconShare } from "../../icons/IconShare.svg";
import Container from "../Container/Container";
import ContentAside from "../ContentAside/ContentAside";

import styles from "./Share.module.scss";


function useDelayUnmount(isMounted, delayTime) {
  const [showDiv, setShowDiv] = useState(false);
  useEffect(() => {
    let timeoutId;
    if (isMounted && !showDiv) {
      setShowDiv(true);
    } else if (!isMounted && showDiv) {
      timeoutId = setTimeout(() => setShowDiv(false), delayTime);
    }
    return () => clearTimeout(timeoutId);
  }, [isMounted, delayTime, showDiv]);
  return showDiv;
}

const ShowWithAnimation = ({ children, isMounted }) => {
  const showDiv = useDelayUnmount(isMounted, 300);

  return (
    <>
      {showDiv && (
        <div
          className={classNames(
            styles["overlay-wrapper"],
            isMounted ? styles.start : styles.end
          )}
        >
          {children}
        </div>
      )}
    </>
  );
};

const Share = ({ backUrl, top }) => {
  const { meInteresa, meInteresaLink, meInteresaName } = useContext(AppContext);

  const url = window.location.href;
  const text = document.title;
  const [scrollPosition, setScrollPosition] = useState(0);
  const [inFooter, setInFotter] = useState(false);
  const is768 = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);

  const handleClick = () => {
    setToggle((toggle) => !toggle);
  };

  const defaultClass = styles.share;
  const conditionalClass = toggle ? styles.active : "";
  const handleScroll = () => {
    const currentPosition =
      window.scrollY || document.documentElement.scrollTop;
    setScrollPosition(currentPosition);
    setInFotter(true);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);
  useKeypress("Escape", () => {
    if (toggle === true) {
      setToggle(false);
    }
  });
  const returnShareContent = () => {
    return (
      <div
        className={classNames(
          styles.shareblock,
          meInteresa && meInteresaLink ? styles.meInteresa : ""
        )}
      >
        <div className={classNames(defaultClass, conditionalClass)}>
          <ShowWithAnimation isMounted={toggle}>
            <div
              className={styles.overlay}
              onClick={handleClick}
              aria-hidden="true"
            ></div>
          </ShowWithAnimation>
          <div className={styles.share__content}>
            {meInteresa && meInteresaLink && (
              <Link
                to={meInteresaLink}
                className={classNames(
                  styles.btnActive,
                  styles.share__btn,
                  styles.btnMeInteresa
                )}
                onClick={() => {
                  // Send event to GTM
                  sendEvent("interested", {
                    click_detail: meInteresaName,
                  });
                }}
              >
                {t("Me interesa")}
              </Link>
            )}
            <div className={styles["button-wrapper"]}>
              <button
                className={classNames(
                  styles.share__btn,
                  styles["share__btn--share"]
                )}
                onClick={handleClick}
              >
                {is768 ? (
                  <>
                    <span className={styles.share__title}>
                      {t("Compartir")}
                    </span>
                    <IconArrowUp />
                  </>
                ) : (
                  <IconShare />
                )}
              </button>
              <ul className={classNames(styles.share__list)}>
                <li>
                  <FacebookShareButton
                    className={styles["share__btn-social"]}
                    quote={text}
                    resetButtonStyle={false}
                    url={url}
                    onClick={() => {
                      // Send event to GTM
                      sendEvent("share_social", {
                        click_detail: "Facebook",
                      });
                    }}
                  >
                    <svg
                      width="18"
                      height="24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.857 20.688v-7.926h2.66l.399-3.09H9.857V7.7c0-.894.248-1.503 1.531-1.503l1.635-.001V3.432a22.169 22.169 0 0 0-2.383-.12c-2.358 0-3.973 1.44-3.973 4.082v2.279H4v3.09h2.667v7.925h3.19Z"
                        fill="currentColor"
                      />
                    </svg>
                    {is768 && <span>Facebook</span>}
                  </FacebookShareButton>
                </li>
                <li>
                  <TwitterShareButton
                    className={styles["share__btn-social"]}
                    resetButtonStyle={false}
                    title={text}
                    url={url}
                    onClick={() => {
                      // Send event to GTM
                      sendEvent("share_social", {
                        click_detail: "Twitter",
                      });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="none"
                    >
                      <path
                        fill="currentColor"
                        d="M10.714 7.623 17.416 0h-1.588l-5.821 6.617L5.36 0H0l7.028 10.007L0 18h1.588l6.144-6.99L12.64 18H18L10.714 7.623ZM8.54 10.095l-.713-.997L2.16 1.171H4.6l4.573 6.4.71.997 5.945 8.318h-2.44l-4.85-6.79Z"
                      />
                    </svg>
                    {is768 && <span>X</span>}
                  </TwitterShareButton>
                </li>
                <li>
                  <LinkedinShareButton
                    className={styles["share__btn-social"]}
                    resetButtonStyle={false}
                    title={text}
                    url={url}
                    onClick={() => {
                      // Send event to GTM
                      sendEvent("share_social", {
                        click_detail: "Linkedin",
                      });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="none"
                    >
                      <path
                        fill="currentColor"
                        d="M16 9.778V16h-3.453v-5.778c0-1.422-.505-2.4-1.768-2.4-.926 0-1.516.711-1.768 1.334-.085.266-.085.533-.085.888V16H5.558s.084-9.778 0-10.756H9.01v1.512c.42-.712 1.263-1.778 3.115-1.778 2.19 0 3.874 1.51 3.874 4.8ZM1.937 0C.757 0 0 .8 0 1.867c0 1.066.758 1.866 1.937 1.866s1.937-.8 1.937-1.866C3.874.8 3.116 0 1.937 0ZM.168 16h3.453V5.244H.168V16Z"
                      />
                    </svg>
                    {is768 && <span>LinkedIn</span>}
                  </LinkedinShareButton>
                </li>
                <li>
                  <WhatsappShareButton
                    className={styles["share__btn-social"]}
                    resetButtonStyle={false}
                    title={text}
                    url={url}
                    onClick={() => {
                      // Send event to GTM
                      sendEvent("share_social", {
                        click_detail: "Whatsapp",
                      });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      fill="none"
                    >
                      <g fill="currentColor" clipPath="url(#a)">
                        <path d="M11.838 9.694c-.03-.015-1.197-.59-1.405-.664a.807.807 0 0 0-.271-.06c-.158 0-.29.078-.393.232-.117.174-.47.586-.578.71-.015.015-.034.035-.046.035-.01 0-.19-.075-.245-.098-1.252-.544-2.201-1.85-2.332-2.071-.018-.032-.019-.046-.02-.046.005-.017.048-.06.07-.081.063-.063.132-.146.198-.226l.094-.112c.097-.112.14-.2.19-.3l.026-.053a.544.544 0 0 0-.016-.512c-.027-.055-.52-1.244-.572-1.369-.126-.301-.293-.442-.524-.442l-.09.004c-.11.005-.706.083-.97.25-.28.176-.753.738-.753 1.727 0 .89.565 1.73.807 2.05l.033.047c.929 1.356 2.086 2.361 3.26 2.83 1.13.451 1.664.503 1.968.503.128 0 .23-.01.32-.019l.058-.005c.39-.035 1.248-.48 1.443-1.021.153-.427.194-.894.092-1.063-.07-.115-.191-.173-.344-.246Z" />
                        <path d="M8.142.5C3.809.5.283 3.999.283 8.3c0 1.39.373 2.752 1.078 3.944L.01 16.226a.208.208 0 0 0 .26.264l4.151-1.319a7.912 7.912 0 0 0 3.72.927C12.475 16.098 16 12.6 16 8.3 16 3.999 12.475.5 8.142.5Zm0 13.974a6.226 6.226 0 0 1-3.425-1.021.208.208 0 0 0-.177-.025l-2.08.661.671-1.98a.208.208 0 0 0-.029-.19A6.09 6.09 0 0 1 1.917 8.3c0-3.404 2.793-6.174 6.225-6.174 3.432 0 6.224 2.77 6.224 6.175 0 3.404-2.792 6.174-6.224 6.174Z" />
                      </g>
                      <defs>
                        <clipPath id="a">
                          <path
                            fill="#fff"
                            d="M0 0h16v16H0z"
                            transform="translate(0 .5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    {is768 && <span>Whatsapp</span>}
                  </WhatsappShareButton>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`${styles.wrapper} ${inFooter ? styles.inFooter : ""} ${
        toggle ? styles.active : ""
      }`}
    >
      <Container>
        <ContentAside aside={!backUrl && returnShareContent()} inBottom={true}>
          <div className={`${styles.buttons_wrapper}`}>
            <div className={`${styles.return}`}>
              {backUrl && (
                <div className={`${styles.share__content}`}>
                  <Link
                    to={backUrl?.["url"]}
                    className={classNames(styles.btnActive, styles.share__btn)}
                  >
                    <IconArrowUp />
                    {t("Volver")}
                  </Link>
                </div>
              )}
            </div>

            {backUrl && returnShareContent()}
          </div>
        </ContentAside>
      </Container>
    </div>
  );
};

export default Share;
