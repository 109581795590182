const getEnabledLanguages = (locales) => {
  if (!locales || Object.entries(locales).length <= 0) {
    return null;
  }

  const enabled = Object.entries(locales).filter((locale) => {
    const [, localeData] = locale;

    return localeData.enabled;
  });

  if (!enabled || enabled.length <= 0) {
    return null;
  }

  return Object.fromEntries(enabled);
};

const getDefaultLanguage = (locales) => {
  if (!locales || Object.entries(locales).length <= 0) {
    return null;
  }

  const enabledLanguages = getEnabledLanguages(locales);

  const found = Object.entries(enabledLanguages).find((locale) => {
    const [, localeData] = locale;

    return localeData.default;
  });

  if (!found || found.length <= 0) {
    return null;
  }

  return found[0];
};

const isCorrectLanguage = (locales, localeCode = "") => {
  if (!locales || Object.entries(locales).length <= 0 || !localeCode) {
    return null;
  }

  const enabledLanguages = getEnabledLanguages(locales);

  return Object.keys(enabledLanguages).includes(localeCode);
};

export { getEnabledLanguages, getDefaultLanguage, isCorrectLanguage };
