import Loader from "../Loader/Loader";

import styles from "./ViewLoader.module.scss";

const ViewLoader = () => {
  return (
    <div className={styles.loader}>
      <Loader />
    </div>
  );
};

export default ViewLoader;
