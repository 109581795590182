import Link from "../../../../elements/Link/Link";

import styles from "./LegalMenu.module.scss";

const LegalMenu = ({ links = [] }) => {
  if (links?.length <= 0) {
    return "";
  }

  return (
    <ul className={styles.legalMenu}>
      {links
        .map((link, index) => {
          const { relative, title, below: children, enabled } = link;

          if (!enabled) {
            return "";
          }

          const key = `${index}-${title}`;

          const url = relative;

          return (
            <li key={key}>
              {url ? <Link to={url}>{title}</Link> : title}
              {children?.length > 0 && <LegalMenu links={children} />}
            </li>
          );
        })
        .filter(Boolean)}
    </ul>
  );
};

export default LegalMenu;
