import PropTypes from "prop-types";

import classnames from "classnames";

import styles from "../ButtonLink/ButtonLink.module.scss";

const Button = ({
  type,
  onClick,
  className,
  children,
  styleVariant,
  displayBlock,
}) => {
  const buttonClass = classnames(
    styleVariant ? styles.buttonLink : undefined,
    styleVariant ? styles[styleVariant] : undefined,
    {
      [styles.displayBlock]: displayBlock,
    },
    className
  );

  return (
    <button className={buttonClass} type={type} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;

Button.propTypes = {
  type: PropTypes.oneOf(["button", "reset", "submit"]),
  styleVariant: PropTypes.oneOf([
    "black",
    "white",
    "transparent",
    "transparent--dark",
  ]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  displayBlock: PropTypes.bool,
};

Button.defaultProps = {
  displayBlock: false,
  type: "button",
  onClick: () => {},
  children: "A button",
};
