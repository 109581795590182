import React from "react"

import { Field } from "formik"

const Hidden = ({ field }) => {
  const { webform_key, webform_id } = field

  return <Field type="hidden" id={webform_id} name={webform_key} />
}

export default Hidden
