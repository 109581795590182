import React from "react";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import classNames from "classnames";
import { Markup } from "interweave";


export const ThankYouNewsletter = ({ message, className, closeButton }) => {
  const { t } = useTranslation();

  const renderGenericMessage = () => (
    <>
      <h2>{t("Gracias por suscribirte a nuestras noticias")}</h2>
    </>
  );

  const formThanksClassName = classNames("form-thanks-message", className);

  // console.log(closeButton)

  return (
    <div className={formThanksClassName}>
      {message ? <Markup noWrap content={message} /> : renderGenericMessage()}

      {closeButton && <p>{closeButton}</p>}
    </div>
  );
};

ThankYouNewsletter.propTypes = {
  message: PropTypes.string,
  closeButton: PropTypes.object,
  className: PropTypes.string
};
ThankYouNewsletter.defaultProps = {
  message: "",
  closeButton: null,
  className: null
};
