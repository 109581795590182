import { getApiUrl, getCmsBasicAuth } from "./env";

const getApiEndpointUrl = (endpoint) => {
  if (!endpoint) {
    return "";
  }

  const apiUrl = getApiUrl();

  return `${apiUrl}${endpoint}`;
};

const getAllLiteralsApiUrl = (locale = "") => {
  if (Array.isArray(locale)) {
    locale = locale[0]
  }

  if (!locale) {
    return "";
  }

  const url = getApiEndpointUrl(`/${locale}/export/all-literals`);

  return url;
};

const getNodeApiUrl = (locale = "", slug = "") => {
  if (!locale || !slug) {
    return "";
  }

  const url = getApiEndpointUrl(
    `/${locale}/export/get-node/0?node_path=${encodeURIComponent(slug)}`
  );

  return url;
};

const getRegionsApiUrl = (locale = "", id = "") => {
  if (!locale || !id) {
    return "";
  }

  const url = getApiEndpointUrl(`/${locale}/export/get-region/${id}`);

  return url;
};

const submitRequest = async (url, options = {}) => {
  if (!url) {
    return null;
  }

  const { username, password } = getCmsBasicAuth();

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  if (username && password) {
    headers.append("Authorization", "Basic " + btoa(username + ":" + password));
  }

  const defaultOptions = {
    method: "GET",
  };

  if (options.headers) {
    const headersObject = Object.fromEntries(options.headers);
    for (const key in headersObject) {
      const value = headersObject[key];

      headers.set(key, value);
    }
  }

  return await fetch(url, {
    ...defaultOptions,
    ...options,
    headers,
  });
};

export {
  getApiEndpointUrl,
  getAllLiteralsApiUrl,
  getNodeApiUrl,
  getRegionsApiUrl,
  submitRequest,
};
