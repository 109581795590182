import { useState } from "react";
import { useTranslation } from "react-i18next";

import InnerDropdown from "../../../../../components/InnerDropdown/InnerDropdown";
import LanguageSwitcher from "../../../../../components/LanguageSwitcher/LanguageSwitcher";
import Link from "../../../../../elements/Link/Link";
import LoginAndSuscribe from "../../../../../elements/LoginAndSuscribe/LoginAndSuscribe";
import { sendEvent } from "../../../../../helpers/gtm";
import { getLocalizedHomeUrl } from "../../../../../helpers/localized-url";
import useMainMenuData from "../../../../../hooks/use-main-menu-data";
import useNodeData from "../../../../../hooks/use-node-data";

import styles from "./DropdownMobile.module.scss";


const DropdownMobile = ({ open, closeMenuMovil, openWebformModal }) => {
  const mainMenuData = useMainMenuData();
  const { getNodeData } = useNodeData();
  const nodeData = getNodeData();
  const { i18n } = useTranslation();
  const [openStates, setOpenStates] = useState(
    Array(mainMenuData?.length).fill(false)
  );

  return (
    <nav
      aria-label="Menu nav"
      className={`${styles.nav} ${open ? styles.open : ""}`}
    >
      <div className={styles.nav_wrapper}>
        <ul className={styles.ul}>
          {mainMenuData?.map((parent, index) => {
            const { title, below: children, relative: url } = parent;
            const hasDropdown = children?.length > 0;

            return (
              <li
                key={index}
                className={
                  hasDropdown && openStates[index] ? styles.isOpen : ""
                }
              >
                {!hasDropdown && (
                  <Link
                    className={styles.navItem}
                    to={url ?? getLocalizedHomeUrl(i18n.language)}
                    onClick={() => {
                      // Send event to GTM
                      sendEvent("click_menu", {
                        click_detail: title,
                      });

                      closeMenuMovil();
                    }}
                  >
                    {title}
                  </Link>
                )}
                {hasDropdown && (
                  <ToggleNav
                    title={title}
                    children={children}
                    isOpen={openStates[index]}
                    setIsOpen={(value) => {
                      const updatedStates = [...openStates];
                      updatedStates[index] = value;
                      setOpenStates(updatedStates);
                    }}
                    closeMenuMovil={closeMenuMovil}
                  />
                )}
              </li>
            );
          })}
        </ul>
        <LoginAndSuscribe openWebformModal={openWebformModal} />
        <LanguageSwitcher localizedSlugs={nodeData?.urls || []} />
      </div>
    </nav>
  );
};

const ToggleNav = ({ title, children, isOpen, setIsOpen, closeMenuMovil }) => {
  return (
    <>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={`${styles.navItem} ${isOpen ? styles.buttonOpen : ""}`}
      >
        {title}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="8"
          fill="none"
        >
          <path stroke="#1A1A1A" strokeWidth="2" d="m1 1 5.5 5L12 1" />
        </svg>
      </button>

      <div className={`${styles.content} ${isOpen ? styles.openContent : ""}`}>
        {" "}
        <InnerDropdown
          data={children}
          parent={title}
          closeMenuMovil={closeMenuMovil}
        />
      </div>
    </>
  );
};

export default DropdownMobile;
