import { useState, useContext } from "react";

import MarkeeLink from "../../../components/MarkeeLink/MarkeeLink";
import { AppContext } from "../../../context/ContextWrapper";
import { MenuProvider } from "../../../context/MenuContext";
import DecorativeMovil from "../../../elements/DecorativeMovil/DecorativeMovil";
import KutxaHomeLink from "../../../elements/KutxaHomeLink/KutxaHomeLink";
import DropdownMobile from "./components/DropdownMobile/DropdownMobile";
import Hamburger from "./components/Hamburger/Hamburger";

import styles from "./HeaderMobile.module.scss";

const HeaderMobile = ({ openWebformModal }) => {
  return (
    <MenuProvider>
      <Header openWebformModal={openWebformModal} />
    </MenuProvider>
  );
};

const Header = ({ openWebformModal }) => {
  const [open, setOpen] = useState(false);
  const { viewColors } = useContext(AppContext);
  const handleClickClose = () => {
    setOpen(false);
  };
  // const { closeMenuMovil } = useContext(MenuContext);
  return (
    <>
      <header className={styles.header}>
        <KutxaHomeLink className={styles.linkMobile} />
        <DecorativeMovil />
        <Hamburger open={open} onClick={() => setOpen(!open)} />
      </header>
      {
        <DropdownMobile
          closeMenuMovil={handleClickClose}
          openWebformModal={openWebformModal}
          open={open ? "open" : ""}
        />
      }
      <MarkeeLink colorApi={viewColors} />{" "}
    </>
  );
};

export default HeaderMobile;
