import PropTypes from "prop-types";

import classNames from "classnames";

import styles from "./ContentAside.module.scss";

const ContentAside = ({ children, aside, inBottom }) => {
  const classToWrapper = classNames(
    styles.grid_expanded,
    inBottom ? styles.inbottom : ""
  );
  const classToMain = classNames(
    styles.mainContent,
    inBottom ? styles.inbottom : ""
  );
  const classToAside = classNames(
    styles.sidebarContent,
    inBottom ? styles.inbottom : ""
  );
  const classToSeparator = classNames(
    styles.separator,
    aside && inBottom ? styles.nohasborder : ""
  );
  return (
    <div className={classToWrapper}>
      <div className={classToMain}>{children}</div>
      <div className={classToSeparator}></div>
      <div className={classToAside}>{aside}</div>
    </div>
  );
};
export default ContentAside;

ContentAside.propTypes = {
  aside: PropTypes.node,
  children: PropTypes.node.isRequired,
  inBottom: PropTypes.bool,
};

ContentAside.defaultProps = {
  aside: null,
  children: null,
  inBottom: false,
};
