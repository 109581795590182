const ArrowSideMovile = () => {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.25H7.27273M7.27273 1.25V8.75M7.27273 1.25L0.727273 8"
        stroke="#1A1A1A"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default ArrowSideMovile;
