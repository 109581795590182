import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";

import classNames from "classnames";
import es from "date-fns/locale/es";
import eu from "date-fns/locale/eu";
import { ErrorMessage, Field } from "formik";

import {
  getRequiredMark,
  getValidationClass,
  getWrapperClass,
} from "../../../../helpers/form";

import "../../../../styles/vendors/react-datepicker/datepicker.scss";
import styles from "./Datetime.module.scss";

registerLocale("es", es);
registerLocale("eu", eu);

const DateField = ({ field, formik, handleChange }) => {
  const { errors, touched } = formik;
  const { i18n } = useTranslation();

  const {
    required,
    title,
    webform_key,
    webform_id,
    attributes,
    wrapperAttributes,
  } = field;

  let isRequired = required === "true";
  let requiredMark = getRequiredMark(isRequired);

  let wrapperAttributesClass = "";
  if (wrapperAttributes?.class?.length > 0) {
    wrapperAttributesClass = wrapperAttributes.class.join(" ");
  }

  const attributesClass = attributes?.class ?? "";
  const validationClass = getValidationClass(
    errors[webform_key],
    touched[webform_key]
  );

  const hasErrors = errors[webform_key] && touched[webform_key] ? true : false;

  const fieldClass = `${attributesClass} ${validationClass}`.trim();

  let wrapperClass = getWrapperClass(wrapperAttributesClass);
  const containerClass = `form-group ${webform_key}-container`;

  const ref = React.useRef(null);
  const [hasValue, setHasValue] = React.useState(false);

  const cssHasValue = hasValue ? styles["input--has-value"] : undefined;
  const inputClassNames = classNames(styles.input, cssHasValue, fieldClass);

  return (
    <div className={`${containerClass} ${wrapperClass}`}>
      <div className="field-error-group">
        <Field
          id={webform_id}
          name={webform_key}
          required={isRequired}
          type="date"
        >
          {({ field, form, meta, ...props }) => (
            <div className={styles.wrapper}>
              <label htmlFor={webform_id} className={styles.label}>
                {title}
                {requiredMark}
              </label>
              <DatePicker
                {...field}
                id={webform_id}
                className={inputClassNames}
                dateFormat="dd/MM/yyyy"
                locale={i18n.language}
                ref={ref}
                selected={field.value}
                onChange={(value) => {
                  const valueToSet = value !== null ? value : "";

                  form.setFieldValue(field.name, valueToSet);

                  setHasValue(valueToSet !== "");
                }}
                {...props}
              />
              {/* {meta.touched && meta.error && (
                <div className="error">{meta.error}</div>
              )} */}
            </div>
          )}
        </Field>
        {hasErrors && (
          <p>
            <ErrorMessage name={webform_key} />
          </p>
        )}
      </div>
    </div>
  );
};

export default DateField;
