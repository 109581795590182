import React from "react"

import { ErrorMessage, Field } from "formik"

import {
  getRequiredMark,
  getValidationClass,
  getWrapperClass,
} from "../../../../helpers/form"
import { RadioSingle } from "./RadioSingle"

const RadioGroup = ({ field, formik }) => {
  const { values, errors, touched } = formik

  const {
    options,
    required,
    title,
    webform_key,
    attributes,
    wrapperAttributes,
  } = field

  let isRequired = required === "true"
  let requiredMark = getRequiredMark(isRequired)

  let wrapperAttributesClass = ""
  if (wrapperAttributes?.class?.length > 0) {
    wrapperAttributesClass = wrapperAttributes.class.join(" ")
  }

  const attributesClass = attributes?.class ?? ""
  const validationClass = getValidationClass(
    errors[webform_key],
    touched[webform_key]
  )

  const hasErrors = errors[webform_key] && touched[webform_key] ? true : false

  const fieldClass = `${attributesClass} ${validationClass}`.trim()

  let wrapperClass = getWrapperClass(wrapperAttributesClass)
  const containerClass = `form-group ${webform_key}-container`

  return (
    <div className={`${containerClass} ${wrapperClass}`}>
      <div className={`radio-group ${fieldClass}`}>
        <p className="rg-title">
          {title}
          {requiredMark}
        </p>

        {options.map(option => {
          const { text, value } = option
          return (
            <div className="rg-item" key={value}>
              <Field
                label={text}
                name={webform_key}
                value={value}
                checked={values[webform_key] === value}
                component={RadioSingle}
              />
            </div>
          )
        })}
      </div>
      {hasErrors && (
        <p>
          <ErrorMessage name={webform_key} />
        </p>
      )}
    </div>
  )
}

export default RadioGroup
