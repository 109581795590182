import { useMediaQuery } from "react-responsive";

import HeaderDesktop from "./HeaderDesktop/HeaderDesktop";
import HeaderMobile from "./HeaderMobile/HeaderMobile";

const Header = ({ openWebformModal }) => {
  const isMobileOrTablet = useMediaQuery({
    query: "(max-width: 1023px)",
  });
  return isMobileOrTablet ? (
    <HeaderMobile openWebformModal={openWebformModal} />
  ) : (
    <HeaderDesktop openWebformModal={openWebformModal} />
  );
};

export default Header;
