import React from "react"

import t from "prop-types"

import Classnames from "classnames"

export const RadioSingle = props => {
  const {
    label,
    value,
    disabled,
    checked,
    field: { name, onChange, onBlur },
  } = props

  const radioClass = Classnames("radio-group", {
    disabled: disabled,
  })

  return (
    <label className={radioClass}>
      {label}
      <input
        className="radio-input"
        type="radio"
        disabled={disabled}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
      />
      <span className="radio">
        <span className="radio-active" />
      </span>
    </label>
  )
}

RadioSingle.protoTypes = {
  label: t.string,
  value: t.string,
  disabled: t.bool,
  checked: t.bool,
  field: t.shape({ name: t.string, onChange: t.func, onBlur: t.func }),
}

RadioSingle.defaultProps = {
  label: "",
  disabled: false,
  checked: false,
  field: { name: "", onChange: () => {}, onBlur: () => {} },
}
