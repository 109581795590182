import { useContext } from "react";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import classnames from "classnames";

import { AppContext } from "../../context/ContextWrapper";
import { sendEvent } from "../../helpers/gtm";
import Button from "../Button/Button";

import styles from "./LoginAndSuscribe.module.scss";


const LoginAndSuscribe = ({
  scrolled = false,
  mouseenter = false,
  openWebformModal,
}) => {
  const { t } = useTranslation();
  const { isHome, viewColors } = useContext(AppContext);
  const buttonClass = classnames(
    styles.buttonWhite,
    scrolled && styles.scrolled,
    mouseenter && styles.scrolled,
    !isHome && styles["is-sticky"]
  );

  return (
    <div
      className={styles.wrapper}
      style={{
        "--color": viewColors?.["field_color_zona_i"],
        "--colorii": viewColors?.["field_color_zona_ii"],
      }}
    >
      {/* Hide this link temporary */}
      {/* <Link className={buttonClass} to={"http://www.kutxakide.eus"}>
        {t("Entra en Kutxakide")}
      </Link> */}
      <Button
        className={buttonClass}
        type="button"
        onClick={() => {
          // Send event to GTM
          sendEvent("subscribe", {});

          openWebformModal();
        }}
      >
        {t("Suscríbete")}
      </Button>
    </div>
  );
};

export default LoginAndSuscribe;

LoginAndSuscribe.propTypes = {
  scrolled: PropTypes.bool,
  mouseenter: PropTypes.bool,
};
