/**
 * See: https://larsmagnus.co/blog/how-to-test-for-accessibility-with-axe-core-in-next-js-and-react#adding-axe-corereact-to-a-react-project
 *
 * "By default, axe DevTools currently tests according to the WCAG 2.0 AA standard."
 * https://docs.deque.com/devtools-html/4.0.0/en/ar-custom-rules#ruleset-configuration
 * https://github.com/dequelabs/axe-core/blob/v4.6.0/doc/rule-descriptions.md
 *
 * @param App
 * @param config
 * @returns {Promise<void>}
 */

export const reportAccessibility = async (App, config) => {
  if (process.env.NODE_ENV !== "production") {
    const axe = await import("@axe-core/react");
    const ReactDOM = await import("react-dom");

    axe.default(App, ReactDOM, 3500, config);
  }
};

export default reportAccessibility;
