import { formatComponentName } from "./block";

const getTemplateFromNode = (node) => {
  const { type = "", field_plantilla_front = "" } = node;

  if (!type) {
    return "";
  }

  const fileName = [type, field_plantilla_front]
    .filter(Boolean)
    .join("-")
    .replace("_", "-")
    .toLowerCase();

  return fileName;
};

const getBlocksFromNode = (node) => {
  if (!node || !node.field_pagina_bloques) {
    return null;
  }

  const fileNames = node.field_pagina_bloques.map((block) => {
    const { type, paragrahp_type: paragraphType = "" } = block;

    const checkType = type === "paragraph" ? paragraphType : type;
    const componentName = formatComponentName(checkType);

    return componentName;
  });

  return fileNames;
};

export { getTemplateFromNode, getBlocksFromNode };
