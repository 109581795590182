import { createRef, useState } from "react";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import PropTypes from "prop-types";

import classNames from "classnames";
import { Form, Formik } from "formik";
import { Markup } from "interweave";
import * as Yup from "yup";

import Button from "../../elements/Button/Button";
import { Heading } from "../../elements/Heading/Heading";
import { getApiEndpointUrl, submitRequest } from "../../helpers/api";
import { getReCaptchaKey } from "../../helpers/env";
import { sendFormEvent } from "../../helpers/gtm";
import Checkbox from "./Fields/Checkbox/Checkbox";
import CheckboxGroup from "./Fields/Checkbox/CheckboxGroup";
import Datetime from "./Fields/Datetime/Datetime";
import DocumentFile from "./Fields/DocumentFile/DocumentFile";
import Email from "./Fields/Email/Email";
import FormMarkup from "./Fields/FormMarkup/FormMarkup";
import Hidden from "./Fields/Hidden/Hidden";
import Html from "./Fields/Html/Html";
import RadioGroup from "./Fields/Radio/RadioGroup";
import SelectWithGroups from "./Fields/SelectWithGroups/SelectWithGroups";
import Submit from "./Fields/Submit/Submit";
import Tel from "./Fields/Tel/Tel";
import Textarea from "./Fields/Textarea/Textarea";
import Textfield from "./Fields/Textfield/Textfield";
import { ThankYou } from "./Result/TankYou";
import { ThankYouNewsletter } from "./Result/ThankYouNewsletter";

import styles from "./CmsForm.module.scss";


const CmsFormChild = ({
  formData,
  titleTagName,
  className,
  onSuccessRedirect,
  customInitValues,
  closeThanksButton,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [showThanks, setShowThanks] = useState(false);

  const { i18n, t } = useTranslation();

  const langcode = i18n.language;
  const location = useLocation();

  // Object that maps Drupal taxonomies with options for
  // fields of type "webform_term_select".
  // Add options for other taxonomies here.
  //const vocabularyOptions = {};

  const initValues = (fieldsets) => {
    if (!fieldsets || fieldsets.length <= 0) {
      return {};
    }

    let allValues = [];
    let allValidations = [];

    fieldsets.forEach((fieldset) => {
      const { elements } = fieldset;

      // Take only the fields
      const fields = elements.filter(
        (field) =>
          ![
            "fieldset",
            "webform_markup",
            "webform_actions",
            "processed_text",
          ].includes(field.type)
      );

      // Construct initial values
      const values = fields.map((field) => {
        const { webform_key, type } = field;

        let initialValue = "";
        if (["webform_term_select", "select_with_groups"].includes(type)) {
          initialValue = "0";
        }

        if (type === "radios") {
          const { options } = field;

          initialValue = options?.length > 0 ? options[0].value : "";
        }

        if (type === "checkboxes") {
          const { options } = field;

          initialValue = options?.length > 0 ? [options[0].value] : [];
        }

        if (webform_key === "current_lang") {
          initialValue = langcode;
        }

        if (webform_key === "current_page") {
          initialValue = location?.href ?? "";
        }

        return { [webform_key]: initialValue };
      });

      allValues = [...allValues, ...values];

      // Construct validations
      const validations = fields.map((field) => {
        const { required, type, webform_key } = field;

        const isRequired = required === "true" || required === true;

        let validation = null;

        if (type === "email") {
          if (isRequired) {
            validation = Yup.string()
              .email("Invalid email")
              .required(t("Required"));
          } else {
            validation = Yup.string().email("Invalid email");
          }
        } else if (type === "checkbox" && webform_key === "politica") {
          validation = Yup.bool()
            .oneOf([true], "Required")
            .required(t("Required"));
        } else if (type === "checkboxes") {
          validation = Yup.array().required(
            "At least one checkbox is required"
          );
        } else if (
          ["webform_term_select", "select_with_groups"].includes(type)
        ) {
          if (isRequired) {
            validation = Yup.mixed()
              .notOneOf(["0", "", null, "undefined"], t("Required"))
              .required(t("Required"));
          }
        } else {
          if (isRequired) {
            validation = Yup.string().required(t("Required"));
          }
        }

        if (validation !== null) {
          return { [webform_key]: validation };
        }

        return null;
      });

      allValidations = [...allValidations, ...validations];
    });

    let initialValues = {};
    if (allValues.length > 0) {
      initialValues = Object.assign(...allValues);
    }

    initialValues = {
      ...initialValues,
      ...customInitValues,
    };

    let validationSchema = {};
    if (allValidations.length > 0) {
      const nonEmptyValidations = allValidations.filter((v) => v !== null);
      validationSchema = Yup.object().shape(
        Object.assign(...nonEmptyValidations)
      );
    }

    return { initialValues, validationSchema };
  };

  const renderField = (field, formik) => {
    const { type, webform_key } = field;

    // Skip these fields, we set the value on init
    if (
      ["current_lang", "current_page", "success_message"].includes(webform_key)
    ) {
      return "";
    }

    switch (type) {
      case "hidden": {
        return <Hidden field={field} key={webform_key} />;
      }
      case "textfield": {
        return <Textfield field={field} formik={formik} key={webform_key} />;
      }
      case "select":
      case "webform_term_select": {
        if (type === "webform_term_select") {
          field.options = field?.vocabulary || null;
        }

        return (
          <CheckboxGroup field={field} formik={formik} key={webform_key} />
        );

        // So, the index is "webform_term_select" but is a lie, it's checkboxes as is in design, in case you want to use a real select, you must either change the index in the CMS or use other case.
        //return <Select field={field} formik={formik} key={webform_key}/>;
      }
      case "select_with_groups": {
        return (
          <SelectWithGroups field={field} formik={formik} key={webform_key} />
        );
      }
      case "email": {
        return <Email field={field} formik={formik} key={webform_key} />;
      }
      case "datetime":
      case "date": {
        return <Datetime field={field} formik={formik} key={webform_key} />;
      }
      case "tel": {
        return <Tel field={field} formik={formik} key={webform_key} />;
      }
      case "radios": {
        return <RadioGroup field={field} formik={formik} key={webform_key} />;
      }
      case "textarea": {
        return <Textarea field={field} formik={formik} key={webform_key} />;
      }
      case "checkbox": {
        return <Checkbox field={field} formik={formik} key={webform_key} />;
      }
      case "checkboxes": {
        return (
          <CheckboxGroup field={field} formik={formik} key={webform_key} />
        );
      }
      case "webform_actions": {
        return <Submit field={field} formik={formik} key={webform_key} />;
      }
      case "webform_markup": {
        return <FormMarkup field={field} key={webform_key} />;
      }
      case "webform_document_file":
        return <DocumentFile field={field} formik={formik} key={webform_key} />;
      case "processed_text": {
        return <Html field={field} />;
      }
      default: {
        return "";
      }
    }
  };

  const renderFieldset = (fieldset, formik) => {
    const { title, webform_key, elements, attributes } = fieldset;

    const fieldsetClass = attributes?.class;

    return (
      <fieldset key={webform_key} className={fieldsetClass}>
        {title && title !== "Submit" && (
          <legend className={styles.legend}>{title}</legend>
        )}
        <div className="row no-gutters">
          {elements.map((field) => renderField(field, formik))}
        </div>
      </fieldset>
    );
  };

  const submitForm = async (url = "", data = {}) => {
    // console.log("submitting data...", url, JSON.stringify(data, null, 2))

    const response = await submitRequest(url, {
      method: "POST",
      body: JSON.stringify(data),
    });

    return response;
  };

  const uploadFile = async (field, data, formikActions) => {
    const { setErrors } = formikActions;

    if (
      !field ||
      !field.webform_key ||
      !data ||
      !data.hasOwnProperty(field.webform_key)
    ) {
      return null;
    }

    const fieldData = data[field.webform_key];

    if (!fieldData) {
      return null;
    }

    const endpoint = "/webform_rest/%webform/upload/%field"
      .replace(/%webform/i, field.webform)
      .replace(/%field/i, field.webform_key);

    const url = getApiEndpointUrl(endpoint);

    let formData = new FormData();
    formData.append(field.webform_key, fieldData);

    const headers = new Headers();
    headers.append("Content-Type", "application/octet-stream");
    headers.append(
      "Content-Disposition",
      'file; filename="' + fieldData.name + '"'
    );

    try {
      const response = await submitRequest(url, {
        method: "POST",
        headers: headers,
        body: formData,
      });

      const responseData = await response.json();

      if (!responseData.fid || responseData.fid.length <= 0) {
        setErrors({
          submit: t("No se ha podido adjuntar el fichero: ") + field.title,
          [field.webform_key]: responseData.message,
        });

        return -1;
      }

      // Return file upload id
      return { [field.webform_key]: responseData.fid[0].value };
    } catch (error) {
      console.error(error);

      // Return empty file upload id
      return { [field.webform_key]: "" };
    }
  };

  const uploadFiles = async (data, formikActions) => {
    const allFileFields = getFileFields(formData);

    if (allFileFields.length <= 0) {
      return null;
    }

    let uploadIds = [];

    for (const fileField of allFileFields) {
      const uploadId = await uploadFile(fileField, data, formikActions);

      if (uploadId === -1) {
        return -1;
      }

      uploadIds = { ...uploadIds, ...uploadId };
    }

    return uploadIds;
  };

  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setErrors, setStatus, resetForm } = actions;

    setSubmitting(true);

    try {
      let submitData = {
        ...values,
        webform_id: formId,
      };

      if (typeof executeRecaptcha !== "undefined") {
        const token = await executeRecaptcha("submit");

        submitData.recaptcha_element = token;
      }

      const uploadFilesResponse = await uploadFiles(submitData, actions);

      if (uploadFilesResponse === -1) {
        setSubmitting(false);

        FormRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });

        return null;
      }

      // Update submitData with file upload ids
      submitData = { ...submitData, ...uploadFilesResponse };

      const response = await submitForm(
        getApiEndpointUrl("/webform_rest/submit", { auth: false }),
        { ...submitData, webform_id: formId }
      );

      setSubmitting(false);

      const responseData = await response.json();

      if (response.ok) {
        // Send GTAG event
        sendFormEvent(formId);

        if (typeof onSuccessRedirect === "function") {
          return onSuccessRedirect({
            formId,
            sid: responseData.sid ?? null,
            submitData,
          });
        }

        resetForm({});
        setStatus({ success: true });

        setShowThanks(true);
        window.scrollTo(0, 0);
      } else {
        const { message, error } = responseData;

        const formattedErrors = { submit: message, ...error };
        setErrors(formattedErrors);

        FormRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { id: formId, description, elements } = formData;

  const formTitle = description ?? "";

  const fieldsets = getFieldsets(elements);

  const thanksMessage = getThanksMessage(elements);

  const { initialValues, validationSchema } = initValues(fieldsets);

  const FormRef = createRef(null);

  const formClassName = classNames(styles.form, className);

  return (
    <Formik
      key={formId}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        const { errors } = formik;

        if (showThanks) {
          if (formId === "suscribete") {
            return (
              <ThankYouNewsletter
                message={thanksMessage}
                className={className}
                closeButton={
                  closeThanksButton ? (
                    closeThanksButton
                  ) : (
                    <Button
                      type="button"
                      name={t("Volver al formulario")}
                      ico="arrow"
                      onClick={() => setShowThanks(false)}
                      styleVariant="black"
                    >
                      {t("Cerrar")}
                    </Button>
                  )
                }
              />
            );
          } else {
            return (
              <ThankYou
                message={thanksMessage}
                className={className}
                closeButton={
                  closeThanksButton ? (
                    closeThanksButton
                  ) : (
                    <Button
                      type="button"
                      name={t("Volver al formulario")}
                      ico="arrow"
                      onClick={() => setShowThanks(false)}
                      styleVariant="black"
                    >
                      {t("Cerrar")}
                    </Button>
                  )
                }
              />
            );
          }
        }

        return (
          <Form className={formClassName} ref={FormRef}>
            <header className={styles.header}>
              <Heading tag="h2" size={"m"} className={styles.heading}>
                {t(
                  "¿Quieres estar al día de las últimas novedades de Kutxa Fundazioa?"
                )}
              </Heading>
              {/*}
              <p className={styles.description}>
                {t(
                  "¿Quieres estar al día de las últimas novedades de Kutxa Fundazioa?"
                )}
              </p>
              {*/}
            </header>

            {formTitle && (
              <Markup
                noHtml
                tagName={titleTagName}
                attributes={{ className: "form-title" }}
                content={formTitle}
              />
            )}

            {errors?.submit && (
              <p className="submit-error">
                <span>{t("Attention!")}</span> {errors.submit}
              </p>
            )}

            {/* <p className="mandatory-fields-disclaimer">
              <small>All fields marked with * are mandatory</small>
            </p> */}

            {fieldsets.map((fieldset) => renderFieldset(fieldset, formik))}
          </Form>
        );
      }}
    </Formik>
  );
};

CmsFormChild.propTypes = {
  formData: PropTypes.shape({
    formId: PropTypes.string,
    elements: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string,
        required: PropTypes.bool,
        webform: PropTypes.string,
        webform_id: PropTypes.string,
        webform_key: PropTypes.string,
        webform_parent_key: PropTypes.string,
      })
    ),
  }),
  titleTagName: PropTypes.string,
  className: PropTypes.string,
  onSuccessRedirect: PropTypes.func,
  customInitValues: PropTypes.object,
  closeThanksButton: PropTypes.object,
};

CmsFormChild.defaultProps = {
  formData: { formId: "", elements: [] },
  titleTagName: "h2",
  className: "",
  onSuccessRedirect: null,
  customInitValues: null,
  closeThanksButton: null,
};

const CmsForm = ({
  formData,
  titleTagName,
  className,
  onSuccessRedirect,
  customInitValues,
  closeThanksButton,
}) => {
  const reCaptchaKey = getReCaptchaKey();

  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
      <CmsFormChild formData={formData} />
    </GoogleReCaptchaProvider>
  );
};

CmsForm.propTypes = {
  formData: PropTypes.shape({
    formId: PropTypes.string,
    elements: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string,
        required: PropTypes.bool,
        webform: PropTypes.string,
        webform_id: PropTypes.string,
        webform_key: PropTypes.string,
        webform_parent_key: PropTypes.string,
      })
    ),
  }),
  titleTagName: PropTypes.string,
  className: PropTypes.string,
  onSuccessRedirect: PropTypes.func,
  customInitValues: PropTypes.object,
  closeThanksButton: PropTypes.object,
};

CmsForm.defaultProps = {
  formData: { formId: "", elements: [] },
  titleTagName: "h2",
  className: "",
  onSuccessRedirect: null,
  customInitValues: null,
  closeThanksButton: null,
};

export default CmsForm;

const getFieldsets = (list) => {
  return list.filter((element) => element.type === "fieldset");
};

const getFileFields = (formData) => {
  const { elements } = formData;

  const fieldsets = getFieldsets(elements);

  let allFileFields = [];

  fieldsets.forEach((fieldset) => {
    const { elements } = fieldset;

    // Take only fields of file type
    const fields = elements.filter(
      (field) => field.type === "webform_document_file"
    );

    allFileFields = [...allFileFields, ...fields];
  });

  return allFileFields;
};

const isThanksMessageField = (field) => {
  const { type, webform_key } = field;

  return type === "webform_markup" && webform_key === "success_message";
};

const getThanksMessage = (fieldsets) => {
  if (!fieldsets || fieldsets.length <= 0) {
    return "";
  }

  let fields = [];
  fieldsets.forEach((fieldset) => {
    const { elements } = fieldset;

    if (!elements || elements.length <= 0) {
      return;
    }

    elements.forEach((element) => {
      fields = [...fields, element];
    });
  });

  const thanksMessageField = fields.find((field) =>
    isThanksMessageField(field)
  );

  if (!thanksMessageField || !thanksMessageField.markup) {
    return "";
  }

  return thanksMessageField.markup;
};
