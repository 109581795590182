import PropTypes from "prop-types";

import classnames from "classnames";

import styles from "./Container.module.scss";

const Container = ({ fluid, children, className, tagName, style }) => {
  const CustomTag = tagName ? tagName : "div";

  const fullClassName = classnames(className, styles.container, {
    [styles.fluid]: fluid,
  });
  return (
    <CustomTag className={fullClassName} style={style}>
      {children}
    </CustomTag>
  );
};

export default Container;

Container.propTypes = {
  fluid: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  tagName: PropTypes.string,
};

Container.defaultProps = {
  fluid: false,
  children: null,
  tagName: "div",
};
