import Link from "../../elements/Link/Link";
import MediaMarkup from "../../elements/MediaMarkup/MediaMarkup";
import { sendEvent } from "../../helpers/gtm";

import styles from "./SocialNetworks.module.scss";


const SocialNetworks = ({ links = [] }) => {
  return (
    <ul className={styles.socialNetworks}>
      {links
        .map((link, index) => {
          const { field_enlace_mas_info, field_imagen_listado } = link;

          const url = field_enlace_mas_info;
          const imageData = field_imagen_listado;

          if (!url || !imageData) {
            return "";
          }

          return (
            <li key={index}>
              <Link
                to={url}
                onClick={(e) => {
                  const alt = e.target.alt;
                  // Send event to GTM
                  sendEvent("click_social", {
                    click_detail: alt,
                  });
                }}
              >
                <MediaMarkup data={imageData} />
              </Link>
            </li>
          );
        })
        .filter(Boolean)}
    </ul>
  );
};

export default SocialNetworks;
