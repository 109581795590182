import { useMediaQuery } from "react-responsive";

import { Markup } from "interweave";

import IconKutxa from "../../../../icons/IconKutxa";

import styles from "./Copyright.module.scss";

const Copyright = ({ data }) => {
  const isNotMobile = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  return (
    <>
      {isNotMobile && <Markup noWrap content={data} />}
      {!isNotMobile && (
        <div className={styles.legal}>
          <Markup noWrap content={data} />
          <span className={styles.kutxaLogo}>
            <IconKutxa />
          </span>
        </div>
      )}
    </>
  );
};

export default Copyright;
