import TagManager from "react-gtm-module";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";

import { getGtmId } from "./helpers/env";
import router from "./router";


if (process.env.NODE_ENV !== "development") {
  const gtmId = getGtmId();
  if (gtmId) {
    TagManager.initialize({
      gtmId,
    });
  }
}

function App() {
  return (
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  );
}

export default App;
