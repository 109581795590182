import IconArrowLink from "../../../../../src/icons/IconArrowLink";
import Button from "../../../../elements/Button/Button";
import { Spinner } from "../../../../elements/Spinner/Spinner";

const Submit = ({ field, formik }) => {
  const { isSubmitting, validateForm } = formik;

  const { title, webform_key } = field;

  const disabled = false;

  let className = disabled ? "is-disabled submit-form" : "submit-form";
  if (isSubmitting) {
    className = `${className} is-submitting`;
  }

  return (
    <div>
      <Button
        styleVariant="black"
        key={webform_key}
        type="submit"
        name={webform_key}
        className={className.trim()}
        disabled={disabled}
        onClick={() =>
          validateForm().then((errors) => {
            const errorKeys = Object.keys(errors);
            if (errorKeys.length) {
              const firstErrorKey = errorKeys[0];
              const input = document.querySelector(
                `input[name=${firstErrorKey}]`
              );

              if (input) {
                input.scrollIntoView(false);
              }
            }
          })
        }
      >
        <span>{title}</span>
        <IconArrowLink />
        <Spinner condition={isSubmitting} />
      </Button>
    </div>
  );
};

export default Submit;
