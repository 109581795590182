const ArrowSide = () => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 11L1 1M1 1H11M1 1L10 10" stroke="#1A1A1A" strokeWidth="1.5" />
    </svg>
  );
};

export default ArrowSide;
