import useRegionData from "./use-region-data";

const useFooterLogoData = () => {
  const regionData = useRegionData("footer");

  const getBlockData = () => {
    if (!regionData) {
      return null;
    }

    const block = regionData.find(
      (item) =>
        item.type === "html" &&
        item.id === "block_content_80710c10-2aee-42dc-938b-71be80d5df76"
    );

    if (!block || !block.data) {
      return null;
    }

    return block.data;
  };

  return getBlockData();
};

export default useFooterLogoData;
