import { createContext, useState } from "react";

const BreadCrumbContext = createContext();

const BreadCrumbProvider = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  return (
    <BreadCrumbContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
      {children}
    </BreadCrumbContext.Provider>
  );
};

export { BreadCrumbContext, BreadCrumbProvider };
