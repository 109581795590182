import { useContext } from "react";

import { AppContext } from "../context/ContextWrapper";

const useNodeData = () => {
  const appContext = useContext(AppContext);
  const {
    store: { nodeData },
    setStore,
  } = appContext;

  const getNodeData = () => {
    return nodeData;
  };

  const setNodeData = (nodeData) => {
    setStore((prevStore) => {
      return { ...prevStore, nodeData };
    });
  };

  return { getNodeData, setNodeData };
};

export default useNodeData;
