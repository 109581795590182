import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import classNames from "classnames";

import { MenuContext } from "../../context/MenuContext";
import { sendEvent } from "../../helpers/gtm";
import { getLocalizedHomeUrl } from "../../helpers/localized-url";

import styles from "./InnerDropdown.module.scss";


const InnerDropdown = ({ data, ...props }) => {
  const { i18n } = useTranslation();

  const [activeHover, setActiveHover] = useState();
  const { selectedChild /* , dropdown, selectedParent */ } =
    useContext(MenuContext);

  const handleLinkHover = (title) => {
    // handleDropdown(false);
    setActiveHover(title);
  };
  const handleLinkLeave = () => {
    setActiveHover(null);
  };

  return data?.map((child, index) => {
    const { title, relative, below: children } = child;

    return (
      children?.length > 0 && (
        <React.Fragment key={`${index}-${title}`}>
          <p>
            {relative ? (
              <Link
                className={styles.childTitle}
                to={relative}
                onClick={() =>
                  props?.closeMenuMovil &&
                  typeof props?.closeMenuMovil === "function"
                    ? props?.closeMenuMovil()
                    : null
                }
              >
                {title}
              </Link>
            ) : (
              <span className={styles.childTitle}>{title}</span>
            )}
          </p>
          <ul className={styles.childUl}>
            {children.map((child, index) => {
              const { title, relative: url } = child;
              const classli = classNames(
                /*  selectedChild !== url && dropdown?.title === selectedParent
                  ? styles.inactive
                  : "", */
                selectedChild && selectedChild === url ? styles.active : "",
                activeHover && activeHover !== title ? styles.inactive : ""
              );

              const queryUrl = child?.options?.query ?? "";
              const queryParams = new URLSearchParams(queryUrl).toString();

              const linkUrl = `${url}${queryParams ? "?" + queryParams : ""}`;

              return (
                <li key={`${index}-${title}`} className={classli}>
                  <Link
                    to={linkUrl ?? getLocalizedHomeUrl(i18n.language)}
                    onClick={() => {
                      // Send event to GTM
                      sendEvent("click_menu", {
                        click_detail: title,
                      });

                      // Close mobile menu if closeMenuMovil function is provided
                      if (
                        props?.closeMenuMovil &&
                        typeof props.closeMenuMovil === "function"
                      ) {
                        props.closeMenuMovil();
                      }
                    }}
                    onMouseEnter={() => handleLinkHover(title)}
                    onMouseLeave={() => handleLinkLeave(title)}
                  >
                    {title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </React.Fragment>
      )
    );
  });
};

export default InnerDropdown;
