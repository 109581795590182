import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ErrorMessage, Field } from "formik";

import {
  getRequiredMark,
  getValidationClass,
  getWrapperClass,
} from "../../../../helpers/form";

const Textarea = ({ field, formik }) => {
  const { errors, touched } = formik;

  const { t } = useTranslation();

  const {
    required,
    title,
    webform_key,
    webform_id,
    attributes,
    wrapperAttributes,
  } = field;

  const [characterCount, setCharacterCount] = useState(0);

  const maxLength = 1500;

  let isRequired = required === "true";
  let requiredMark = getRequiredMark(isRequired);

  let wrapperAttributesClass = "";
  if (wrapperAttributes?.class?.length > 0) {
    wrapperAttributesClass = wrapperAttributes.class.join(" ");
  }

  const attributesClass = attributes?.class ?? "";
  const validationClass = getValidationClass(
    errors[webform_key],
    touched[webform_key]
  );

  const hasErrors = errors[webform_key] && touched[webform_key] ? true : false;

  const fieldClass = `${attributesClass} ${validationClass}`.trim();

  let wrapperClass = getWrapperClass(wrapperAttributesClass);
  const containerClass = `form-group ${webform_key}-container`;

  return (
    <div className={`${containerClass} ${wrapperClass}`}>
      <label htmlFor={webform_id}>
        {title}
        {requiredMark}
      </label>
      <div className="field-error-group">
        <Field
          id={webform_id}
          name={webform_key}
          placeholder={title}
          required={isRequired}
          className={fieldClass}
          component={"textarea"}
          maxLength={maxLength}
          rows={7}
          onKeyUp={(e) => {
            setCharacterCount(e.target.value.length);
          }}
        />
        {hasErrors && (
          <p>
            <ErrorMessage name={webform_key} />
          </p>
        )}
      </div>
      <p className="mandatory-fields-disclaimer">
        ({maxLength}) {t("caracteres máx.")} ({characterCount}/{maxLength})
      </p>
    </div>
  );
};

export default Textarea;
