import PropTypes from "prop-types";

import classNames from "classnames";

import styles from "./Heading.module.scss";

export const Heading = ({
  children,
  className,
  color,
  tag,
  size,
  uppercase,
}) => {
  const Heading = tag;

  const fullClassname = classNames(
    styles.heading,
    styles[color],
    styles[size],
    { [styles.uppercase]: uppercase },
    className
  );

  return <Heading className={fullClassname}>{children}</Heading>;
};

Heading.propTypes = {
  tag: PropTypes.node.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(["white"]),
  size: PropTypes.oneOf(["xl", "l", "m", "sm", "xs"]),
  uppercase: PropTypes.bool,
};
