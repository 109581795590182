import { useRef, useState, useLayoutEffect } from "react";
import { useMediaQuery } from "react-responsive";

import { Markup } from "interweave";

import Link from "../../elements/Link/Link";
import useSideBannerData from "../../hooks/use-side-banner-data";
import ArrowSide from "../../icons/ArrowSide";
import ArrowSideMovile from "../../icons/ArrowSideMovile";

import styles from "./MarkeeLink.module.scss";

const MarkeeLink = ({ colorApi }) => {
  const sideBannerData = useSideBannerData();
  const block = useRef(null);
  const [repeat, setRepeat] = useState(1);
  const isMobile = useMediaQuery({
    query: "(max-width: 1024px)",
  });

  useLayoutEffect(() => {
    const height = window.innerHeight;

    const w = block.current?.offsetWidth || 1;
    const times = Math.ceil(height / Math.max(1, w));

    setRepeat(times);
  }, [repeat]);

  if (!sideBannerData) {
    return "";
  }

  const { text = "", link = "" } = sideBannerData;

  const marqueeContent = (
    <div className={styles.line}>
      {text &&
        [...Array(repeat)].map((_, index) => (
          <div key={`link_repeat${index}`} ref={block} className={styles.block}>
            {isMobile ? <ArrowSideMovile /> : <ArrowSide />}
            <Markup content={text} />
          </div>
        ))}
    </div>
  );

  return (
    <div
      className={styles.side}
      style={{ "--color": colorApi?.["field_color_zona_i"] }}
    >
      {link ? <Link to={link}>{marqueeContent}</Link> : marqueeContent}
    </div>
  );
};

export default MarkeeLink;
