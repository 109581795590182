import PropTypes from "prop-types";

import classNames from "classnames";

import styles from "./Spinner.module.scss";

export const Spinner = ({ condition, size }) => {
  const fullClassname = classNames(
    styles.spinner,
    styles[`spinner--size-${size}`]
  );

  return (
    condition && (
      <span className={fullClassname}>
        <svg viewBox="22 22 44 44">
          <circle
            cx="44"
            cy="44"
            r="20.2"
            fill="none"
            strokeWidth="3.6"
          ></circle>
        </svg>
      </span>
    )
  );
};

Spinner.protoTypes = {
  condition: PropTypes.bool.isRequired,
};
