import useRegionData from "./use-region-data";

const useContactAddressData = () => {
  const regionData = useRegionData("footer");

  const getBlockData = () => {
    if (!regionData) {
      return null;
    }

    const block = regionData.find(
      (item) =>
        item.type === "html" &&
        item.id === "block_content_f9b1ef45-9a78-45a8-95f5-0078fd1050c0"
    );

    if (!block || !block.data) {
      return null;
    }

    return block.data;
  };

  return getBlockData();
};

export default useContactAddressData;
