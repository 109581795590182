import { createBrowserRouter } from "react-router-dom";

import ScrollToTop from "./components/ScrollToTop";
import { BreadCrumbProvider } from "./context/BreadCrumbContext";
import Index from "./views/index";
import PageLoadError from "./views/page-load-error";
import Root from "./views/root";
import ViewSwitch, { loader as viewSwitchLoader } from "./views/view-switch";

const router = createBrowserRouter([
  {
    element: <Root />,
    errorElement: <PageLoadError />,
    children: [
      {
        // Index route just to redirect from/to the correct language route
        index: true,
        element: <Index />,
      },
      {
        path: ":locale/*",
        element: (
          <BreadCrumbProvider>
            <ViewSwitch />
            <ScrollToTop />
          </BreadCrumbProvider>
        ),
        loader: viewSwitchLoader,
      },
    ],
  },
]);

export default router;
