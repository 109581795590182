import useRegionData from "./use-region-data";

const useMainMenuData = () => {
  const regionData = useRegionData("header");

  const getBlockData = () => {
    if (!regionData) {
      return null;
    }

    const block = regionData.find(
      (item) => item.type === "menu" && item.id === "system_menu_block_main"
    );

    if (!block || !block.data) {
      return null;
    }

    return block.data;
  };

  return getBlockData();
};

export default useMainMenuData;
