import { useContext } from "react";

import { AppContext } from "../../context/ContextWrapper";

import styles from "./DecorativeMovil.module.scss";

const DecorativeMovil = () => {
  const { mobileVideo } = useContext(AppContext);

  return (
    <div className={styles.decorativeVideo}>
      {mobileVideo ? (
        <video
          key={mobileVideo}
          className={styles.video}
          muted
          autoPlay
          loop
          playsInline
        >
          <source src={`/videos/${mobileVideo}.webm`} type="video/webm" />
          <source src={`/videos/${mobileVideo}.mp4`} type="video/mp4" />
        </video>
      ) : null}
    </div>
  );
};

export default DecorativeMovil;
