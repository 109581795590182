import { useTranslation } from "react-i18next";

import { Markup } from "interweave";

import { Heading } from "../../elements/Heading/Heading";
import sprintf from "../../helpers/sprintf";

import styles from "./Content404.module.scss";

const Content404 = ({ title }) => {
  let { t } = useTranslation();
  const back = "/";
  return (
    <div className={styles.page404}>
      <div className={styles.content}>
        <div className={styles.wrapper}>
          <Heading tag="h1" size={"xl"}>
            {title}
          </Heading>
          <Heading tag="h2" size={"l"}>
            {t(
              "Lo sentimos, la página que buscas no existe o la URL es incorrecta"
            )}
          </Heading>
          <p>
            <Markup
              content={sprintf(
                t(
                  "Puedes volver a la página anterior o <a href='%s'>ir a la página de inicio</a>."
                ),
                back
              )}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Content404;
