import { useContext } from "react";

import { AppContext } from "../../context/ContextWrapper";

import styles from "./DecorativeVideo.module.scss";

const DecorativeVideo = () => {
  const { desktopVideo } = useContext(AppContext);

  return (
    <div className={styles.wrapper_video}>
      {desktopVideo ? (
        <video key={desktopVideo} className={styles.video} muted autoPlay loop>
          <source src={`/videos/${desktopVideo}.webm`} type="video/webm" />
          <source src={`/videos/${desktopVideo}.mp4`} type="video/mp4" />
        </video>
      ) : null}
    </div>
  );
};

export default DecorativeVideo;
