import React from "react"

import { ErrorMessage, Field } from "formik"

import {
  getRequiredMark,
  getValidationClass,
  getWrapperClass,
} from "../../../../helpers/form"

const SelectWithGroups = ({ field, formik }) => {
  const { errors, touched } = formik

  const {
    optgroups,
    empty_option,
    required,
    title,
    webform_key,
    webform_id,
    attributes,
    wrapperAttributes,
  } = field

  let isRequired = required === "true"
  let requiredMark = getRequiredMark(isRequired)

  let wrapperAttributesClass = ""
  if (wrapperAttributes?.class?.length > 0) {
    wrapperAttributesClass = wrapperAttributes.class.join(" ")
  }

  const attributesClass = attributes?.class ?? ""
  const validationClass = getValidationClass(
    errors[webform_key],
    touched[webform_key]
  )

  const hasErrors = errors[webform_key] && touched[webform_key] ? true : false

  const fieldClass = `${attributesClass} ${validationClass}`.trim()

  let wrapperClass = getWrapperClass(wrapperAttributesClass)
  const containerClass = `form-group ${webform_key}-container`

  const allOptions = [{ text: empty_option, value: "0" }]

  return (
    <div className={`${containerClass} ${wrapperClass}`}>
      <label htmlFor={webform_id}>
        {title}
        {requiredMark}
      </label>
      <div className="field-error-group">
        <Field
          id={webform_id}
          name={webform_key}
          placeholder={title}
          required={isRequired}
          className={fieldClass}
          component="select"
        >
          {allOptions.map(option => renderOption(webform_key, option))}
          {optgroups?.length > 0 &&
            optgroups.map(group => renderOptionGroup(webform_key, group))}
        </Field>
        {hasErrors && (
          <p>
            <ErrorMessage name={webform_key} />
          </p>
        )}
      </div>
    </div>
  )
}

export default SelectWithGroups

const renderOptionGroup = (webform_key, group) => {
  const { name, options } = group

  const optgroupLabel = <optgroup>{name}</optgroup>
  const renderedOptions =
    options?.length > 0
      ? group.options.map(option => renderOption(webform_key, option))
      : ""

  return [optgroupLabel, renderedOptions]
}

const renderOption = (webform_key, option) => {
  const { text, value } = option
  return (
    <option value={value} key={`option-${text}-${value}-${webform_key}`}>
      {text}
    </option>
  )
}
