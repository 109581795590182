import { useContext } from "react";
import { useTranslation } from "react-i18next";

import MarkeeLink from "../../../components/MarkeeLink/MarkeeLink";
import { AppContext } from "../../../context/ContextWrapper";
import DecorativeVideo from "../../../elements/DecorativeVideo/DecorativeVideo";
import KutxaHomeLink from "../../../elements/KutxaHomeLink/KutxaHomeLink";
import Link from "../../../elements/Link/Link";
import { getLocalizedHomeUrl } from "../../../helpers/localized-url";
import IconKutxaLetters from "../../../icons/IconKutxaLetters";

import styles from "./SideBanner.module.scss";

const SideBanner = () => {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const { viewColors } = useContext(AppContext);

  return (
    <div className={styles.sideBanner}>
      <div className={styles.banner}>
        <Link to={getLocalizedHomeUrl(locale)} title="Kutxa Fundazioa">
          <IconKutxaLetters />
        </Link>

        <DecorativeVideo />
        <KutxaHomeLink />
      </div>

      <MarkeeLink colorApi={viewColors} />
    </div>
  );
};

export default SideBanner;
