import { useContext } from "react";

import { AppContext } from "../context/ContextWrapper";

const useRegionData = (regionId = "") => {
  const appContext = useContext(AppContext);
  const {
    store: { regionsData },
  } = appContext;

  if (!regionId || !regionsData || !regionsData.hasOwnProperty(regionId)) {
    return null;
  }

  return regionsData[regionId];
};

export default useRegionData;
