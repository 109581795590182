import { getCmsUrl } from "./env";

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const encodeHtml = (text) => {
  const element = document.createElement("div");
  element.innerHTML = text;
  return element.innerText;
};

const cleanUpAbsoluteUrl = (absoluteUrl = "") => {
  const cmsUrl = getCmsUrl();

  const relativeUrl = absoluteUrl.replace(cmsUrl, "");

  return relativeUrl;
};

const isValidUrl = (url) => {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};

const isString = (string) => {
  return typeof string === "string";
};

export {
  capitalizeFirstLetter,
  encodeHtml,
  cleanUpAbsoluteUrl,
  isValidUrl,
  isString,
};
