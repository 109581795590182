import React from "react";

import classNames from "classnames";
import { ErrorMessage, Field } from "formik";

import {
  //getRequiredMark,
  getValidationClass,
  getWrapperClass,
} from "../../../../helpers/form";
import { CheckboxCustom } from "./CheckboxCustom";

import styles from "./CheckboxGroup.module.scss";

const CheckboxGroup = ({ field, formik }) => {
  const { errors, touched } = formik;

  /* console.log(field) */

  const {
    options,
    //required,
    //title,
    webform_key,
    attributes,
    wrapperAttributes,
  } = field;

  //let isRequired = required === "true";
  //let requiredMark = getRequiredMark(isRequired);

  let wrapperAttributesClass = "";
  if (wrapperAttributes?.class?.length > 0) {
    wrapperAttributesClass = wrapperAttributes.class.join(" ");
  }

  const attributesClass = attributes?.class ?? "";
  const validationClass = getValidationClass(
    errors[webform_key],
    touched[webform_key]
  );

  const hasErrors = errors[webform_key] && touched[webform_key] ? true : false;

  const fieldClass = `${attributesClass} ${validationClass}`.trim();

  let wrapperClass = getWrapperClass(wrapperAttributesClass);
  const containerClass = `form-group ${webform_key}-container`;

  return (
    <div className={`${containerClass} ${wrapperClass}`}>
      <div className={`cg-group ${fieldClass}`}>
        {/*
        <p className="cg-title">
          {title}
          {requiredMark}
        </p>
        */}

        <Field name={webform_key}>
          {({ field }) => (
            <div className={classNames(styles.list)}>
              {options.map((option) => {
                const text = option.name || "";
                const value = option.tid || 0;
                return (
                  <div className="cg-item" key={value}>
                    <CheckboxCustom
                      {...field}
                      value={value}
                      className={fieldClass}
                      key={value}
                      required={false}
                    >
                      {`${text}`}
                    </CheckboxCustom>
                  </div>
                );
              })}
            </div>
          )}
        </Field>
      </div>
      {hasErrors && (
        <p class={styles.error}>
          <ErrorMessage name={webform_key} />
        </p>
      )}
    </div>
  );
};

export default CheckboxGroup;
