import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getDefaultLanguage, getEnabledLanguages } from "../helpers/locales";
import { getLocalizedHomeUrl } from "../helpers/localized-url";
import locales from "../locales";

const enabledLanguages = getEnabledLanguages(locales);
const defaultLanguage = getDefaultLanguage(locales);

/**
 *  Index view just to redirect from/to the correct language route
 */

export default function Index() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const localeCodes = Object.keys(enabledLanguages);

  const locale = localeCodes.includes(i18n.language)
    ? i18n.language
    : defaultLanguage;

  useEffect(() => {
    if (!locale) {
      return;
    }

    navigate(getLocalizedHomeUrl(locale));
    return;
  }, [locale, navigate]);

  return "";
}
