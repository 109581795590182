import TagManager from "react-gtm-module";

const sendEvent = (eventName, eventObject) => {
  if (!eventName || !eventObject || !window.dataLayer) {
    return;
  }

  eventObject = translateEventDetails(eventObject);

  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      ...eventObject,
    },
  });
};

const sendFormEvent = (formId) => {
  if (!formId) {
    return;
  }

  switch (formId) {
    case "suscribete":
      sendEvent("newsletter", {});
      break;
    default:
      break;
  }
};

const sendViewEvent = (node) => {
  if (!node) {
    return;
  }

  let section = node?.title ?? "Otros";
  const category = node?.field_tematica
    ? node?.field_tematica[0]?.name ?? ""
    : "";
  const activity = node?.field_tipo_actividad
    ? node?.field_tipo_actividad[0]?.name ?? ""
    : "";
  const people = node?.field_publico ? node?.field_publico[0]?.name ?? "" : "";
  const langcode = node?.langcode === "es" ? "ES" : "EU";

  const urls = node?.urls ?? null;

  let isAgenda = false;
  if (urls) {
    for (const key in urls) {
      if (urls.hasOwnProperty(key)) {
        if (urls[key].includes("agenda")) {
          isAgenda = true;
        }
      }
    }
  }

  let isConocenos = false;
  const nid = node?.nid ?? null;
  const conocenosPages = [37, 38, 40, 330];
  if (nid) {
    isConocenos = conocenosPages.includes(nid);
  }

  if (node?.type === "agrupacion" || node?.field_plantilla_front === "Agenda") {
    section = "Agenda";
  } else if (isAgenda) {
    section = "Agenda";
  } else if (isConocenos) {
    section = "Conócenos";
  } else if (node?.type === "proyectos") {
    section = "Nuestros programas";
  } else if (
    node?.field_plantilla_front === "Convocatorias" ||
    node?.type === "convocatorias"
  ) {
    section = "Convocatorias";
  } else if (node?.type === "lugares") {
    section = "Espacios";
  } else if (
    node?.field_plantilla_front === "Actualidad" ||
    node?.type === "noticia"
  ) {
    section = "Actualidad";
  } else if (node?.title === "Garai Berri Bat") {
    section = "Garai Berri Bat";
  } else if (node?.title === "Historia") {
    section = "Conócenos";
  } else if (node?.field_plantilla_front === "Home") {
    section = "Inicio";
  } else {
    section = "Otros";
  }

  const eventObject = {
    content_group: section,
    content_group_2: category,
    content_group_3: activity,
    content_group_4: people,
    content_group_5: langcode,
  };

  sendEvent("agrupacion", eventObject);
};

const translateEventDetails = (object) => {
  const translations = {
    tematica: "Temática",
    year: "Año",
  };

  const translatedObject = {};
  for (const key in object) {
    let value = object[key];
    const translation = translations[object[key]] || value;

    translatedObject[key] = translation;
  }

  return translatedObject;
};

export { sendEvent, sendFormEvent, sendViewEvent };
