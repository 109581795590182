import { Helmet } from "react-helmet-async";

import useNodeData from "../../hooks/use-node-data";


//const baseUrl = window.location.origin;

const Seo = () => {
  const { getNodeData } = useNodeData();
  const nodeData = getNodeData();

  const metadata = nodeData?.field_metatag || null;
  const urls = nodeData?.urls || null;

  const renderMetaTag = (data) => {
    const { key, value } = data;

    switch (key) {
      case "title":
        return <title key={key}>{value}</title>;
      case "description":
        return <meta key={key} name="description" content={value} />;
      case "canonical_url":
        return <link key={key} rel="canonical" href={value} />;
      default:
        return "";
    }
  };

  /*
  const renderHreflang = (locale) => {
    return (
      <link
        key={`alternate-${locale}`}
        rel="alternate"
        hrefLang={locale}
        href={`${baseUrl}${urls[locale]}`}
      />
    );
  };
   */

  if (!metadata && !urls) {
    return;
  }

  return (
    <Helmet>
      {metadata?.length > 0 && metadata.map((data) => renderMetaTag(data))}
      {/*}
      {urls &&
        Object.keys(urls)?.length > 0 &&
        Object.keys(urls).map((locale) => renderHreflang(locale))}
        {*/}
    </Helmet>
  );
};

export default Seo;
