import useRegionData from "./use-region-data";

const useCopyrightData = () => {
  const regionData = useRegionData("footer");

  const getBlockData = () => {
    if (!regionData) {
      return null;
    }

    const block = regionData.find(
      (item) =>
        item.type === "html" &&
        item.id === "block_content_f0470651-cbfc-4823-9365-468545980495"
    );

    if (!block || !block.data) {
      return null;
    }

    return block.data;
  };

  return getBlockData();
};

export default useCopyrightData;
