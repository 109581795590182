import React from "react";

import { Field } from "formik";

import { getValidationClass, getWrapperClass } from "../../../../helpers/form";
import { UploadFile } from "./UploadFile/UploadFile";

const DocumentFile = ({ field, formik }) => {
  const { errors, touched, setFieldValue, setFieldError } = formik;

  const {
    required,
    title,
    webform_key,
    webform_id,
    attributes,
    description,
    wrapperAttributes,
  } = field;

  let isRequired = required === "true";

  let wrapperAttributesClass = "";
  if (wrapperAttributes?.class?.length > 0) {
    wrapperAttributesClass = wrapperAttributes.class.join(" ");
  }

  const attributesClass = attributes?.class ?? "";
  const validationClass = getValidationClass(
    errors[webform_key],
    touched[webform_key]
  );

  const fieldClass = `${attributesClass} ${validationClass}`.trim();

  let wrapperClass = getWrapperClass(wrapperAttributesClass);
  const containerClass = `form-group ${webform_key}-container`;

  return (
    <div className={`${containerClass} ${wrapperClass}`}>
      <Field name={webform_key}>
        {({ field }) => {
          return (
            <div className={fieldClass}>
              <UploadFile
                {...field}
                title={title}
                description={description}
                webform_id={webform_id}
                webform_key={webform_key}
                fieldClass={fieldClass}
                isRequired={isRequired}
                error={errors[webform_key]}
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
              />
            </div>
          );
        }}
      </Field>
    </div>
  );
};

export default DocumentFile;
