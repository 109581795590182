import {Markup} from "interweave";
import styles from "./Html.module.scss";

const Html = ({field}) => {
  return (
    <div className={styles.htmlContainer}>
      <Markup content={field?.text ?? ""} noWrap/>
    </div>
  );
};

export default Html;
