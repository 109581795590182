import React from "react";

import { Markup } from "interweave";

import { transformBloqueTextoDestacado } from "../../../../transforms/transform-bloque-texto-destacado";

import styles from "./CheckboxCustom.module.scss";

export const CheckboxCustom = ({ children, name, className, ...props }) => {
  return (
    <label>
      <input className={styles.input} type="checkbox" name={name} {...props} />
      <Markup
        content={children}
        transform={transformBloqueTextoDestacado}
        blockList={"p"}
        allowElements={true}
        allowAttributes={true}
        tagName="span"
      />
    </label>
  );
};
