import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { AppContext } from "../../../../../context/ContextWrapper";
import { MenuContext } from "../../../../../context/MenuContext";
import Link from "../../../../../elements/Link/Link";
import { sendEvent } from "../../../../../helpers/gtm";
import { getLocalizedHomeUrl } from "../../../../../helpers/localized-url";
import useMainMenuData from "../../../../../hooks/use-main-menu-data";

import styles from "./MainMenu.module.scss";


const MainMenu = () => {
  const mainMenuData = useMainMenuData();

  const { isHome } = useContext(AppContext);

  const {
    dropdown,
    handleDropdown,
    isScrolled,
    mouseenter,
    handleMouseEnter,
    handleMouseLeave,
    handleClick,
    selectedParent,
  } = useContext(MenuContext);
  const [activeHover, setActiveHover] = useState(null);
  const [active, setActive] = useState(null);

  const { i18n } = useTranslation();

  const handleActive = (link) => {
    const { title, below: children } = link;

    handleDropdown({ title, children });
    if (active !== title) {
      return setActive(title);
    }
    setActiveHover(null);
    setActive(null);
  };
  const handleLinkHover = (link) => {
    const { title } = link;
    if (!dropdown) {
      // handleDropdown(false);
      setActiveHover(title);
      handleMouseEnter(true);
    }
  };
  const handleLinkLeave = () => {
    if (dropdown) {
      //setActive(title);
    } else {
      setActiveHover(null);
      setActive(null);
      // handleMouseLeave();
    }
  };

  return (
    <nav
      className={classNames(styles.mainMenu, isHome && styles["is-home"])}
      aria-label="primary"
    >
      <ul className={styles.menu}>
        {mainMenuData &&
          mainMenuData
            .map((link, index) => {
              const { relative, title, below: children, enabled } = link;
              if (!enabled) {
                return "";
              }

              const key = `${index}-${title}`;
              const url = relative ?? getLocalizedHomeUrl(i18n.locale);
              // const hasDropdown = children?.length > 0 && title !== "Espacios";
              const hasDropdown = children?.length > 0;

              const liClass = classNames(
                isScrolled && styles.scrolled,
                mouseenter && styles.scrolled,
                activeHover !== title &&
                  selectedParent !== title &&
                  mouseenter &&
                  activeHover &&
                  styles.linkenter,
                activeHover === title && styles.activehover,
                active !== title && !isHome && dropdown && styles.inactive,
                activeHover === title && !selectedParent && styles.active,
                active === title && dropdown && styles.dropdown,
                active === title && selectedParent && dropdown && styles.active,
                selectedParent === title && !isHome
                  ? styles.selected
                  : !isHome
                  ? styles.inactive
                  : !isHome && active === title && dropdown
                  ? styles.dropdown
                  : isScrolled
                  ? styles.inactive
                  : ""
              );

              return (
                <li className={liClass} key={key}>
                  {!hasDropdown && (
                    <Link
                      onMouseEnter={() => handleLinkHover(link)}
                      onMouseLeave={() => handleLinkLeave(link)}
                      to={url}
                      onClick={() => {
                        // Send event to GTM
                        sendEvent("click_menu", {
                          click_detail: title,
                        });

                        handleClick();
                      }}
                    >
                      {title}
                    </Link>
                  )}
                  {hasDropdown && (
                    <button
                      onClick={() => {
                        // Send event to GTM
                        sendEvent("click_menu", {
                          click_detail: title,
                        });

                        handleActive(link);
                        handleMouseLeave();
                      }}
                      onMouseEnter={() => handleLinkHover(link)}
                      onMouseLeave={() => handleLinkLeave(link)}
                    >
                      {title}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="8"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.392 4.978 10.017.5l1.044 1.078L5.358 7.1 0 1.56 1.078.517l4.314 4.461Z"
                          clipRule="evenodd"
                          fill="currentColor"
                        />
                      </svg>
                    </button>
                  )}
                </li>
              );
            })
            .filter(Boolean)}
      </ul>
    </nav>
  );
};

export default MainMenu;
