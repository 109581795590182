import { useTranslation } from "react-i18next";

import Container from "../components/Container/Container";
import Seo from "../components/Seo/Seo";
import { getSiteTitle } from "../helpers/env";

const siteName = getSiteTitle();

export default function PageLoadError() {
  const { t } = useTranslation();

  const title = t("Error while loading page");
  const suffix = siteName ? ` | ${siteName}` : "";

  return (
    <>
      <Seo
        metadata={[
          {
            key: "title",
            value: `${title}${suffix}`,
          },
        ]}
      />

      <Container>
        <div
          className="provisional"
          style={{
            display: "grid",
            placeItems: "start",
            padding: "140px 0",
          }}
        >
          <h1>{title}</h1>
        </div>
      </Container>
    </>
  );
}
