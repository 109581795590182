import classNames from "classnames";

import styles from "./Hamburger.module.scss";

const Hamburger = ({ onClick, open }) => {
  const buttonClass = classNames(open && styles.open, styles.hambuguer);
  return (
    <button
      type="button"
      onClick={onClick}
      aria-label="open menu"
      className={buttonClass}
    >
      <span />
      <span />
      <span />
    </button>
  );
};

export default Hamburger;
