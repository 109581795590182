import PropTypes from "prop-types";

import LinkPrefetch from "./LinkPrefetch";

const Link = ({ children, to, target, title, ...other }) => {
  const href = to;
  const domain = process.env.REACT_APP_API_URL;

  // Starts with exactly one slash.
  // const internal = /^\/(?!\/)/.test(href);

  // Starts with exactly one slash OR https://kutxafundazioa.eus/es || https://kutxafundazioa.eus/eu
  const internal = new RegExp(`^(/(?!/)|${domain}/(es|eu))`).test(href);

  if (internal && target !== "_blank") {
    return (
      <LinkPrefetch to={href} {...other} title={title}>
        {children}
      </LinkPrefetch>
    );
  }

  return (
    <>
      <a
        href={href}
        target={target ? target : "_blank"}
        title={title ? title : undefined}
        rel="noopener noreferrer"
        {...other}
      >
        {children}
      </a>
    </>
  );
};

export default Link;

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  target: PropTypes.string,
  title: PropTypes.string,
};
